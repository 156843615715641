import React, { useState } from 'react';
import { db } from './config/firebaseConfig';
import { doc, updateDoc } from 'firebase/firestore';
import './EditClientForm.css';

const EditClientForm = ({ client, onClose, onUpdate }) => {
  const [alias, setAlias] = useState(client.alias);
  const [email, setEmail] = useState(client.email);
  const [comment, setComment] = useState(client.comment);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const clientDocRef = doc(db, "users", client.id);
      await updateDoc(clientDocRef, {
        alias,
        email,
        comment,
      });
      onUpdate(); // Refresh client list
      onClose(); // Close the modal
    } catch (error) {
      console.error("Error updating client:", error);
      setError("Failed to update client. Please try again.");
    }
  };

  return (
    <div className="modal">
      <h2>Edit Client</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Alias:
          <input
            type="text"
            value={alias}
            onChange={(e) => setAlias(e.target.value)}
            required
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            readOnly // Email cannot be changed
            required
          />
        </label>
        <label>
          Comment:
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
          />
        </label>
        {error && <p className="error">{error}</p>}
        <button type="submit">Update Client</button>
        <button type="button" onClick={onClose}>Cancel</button>
      </form>
    </div>
  );
};

export default EditClientForm;
