import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './BlogPost.css';

const BlogPost = ({ title, date, author, content }) => {
  return (
    <>
      <Navbar />
      <div className='blog-post'>
        <h1 className='blog-title'>{title}</h1>
        <div className='blog-meta'>
          <span className='blog-date'>{date}</span>
          <span className='blog-author'>by {author}</span>
        </div>
        <div className='blog-content'>{content}</div>
      </div>
      <Footer />
    </>
  );
};

export default BlogPost;
