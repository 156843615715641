import React from 'react';
import { useNavigate } from 'react-router-dom'; // or your routing library
import './Cancel.css';

const Cancel = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/login');
  };

  return (
    <div className="page-cancel">
      <div className="content-wrap">
        <div className="cancel-page">
          <div className="message">Payment Cancelled</div>
          <div className="description">
            Your payment has been cancelled. If you have any questions or need assistance, please contact our support team.
          </div>
          <button className="go-back-button" onClick={handleGoBack}>
            Go Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cancel;