import React, { useEffect, useState } from 'react';
import './Home.css'; // Import your CSS file for styling
import calmMindImage from './calmMind-removebg-preview.png';
import Navbar from './Navbar';
import NavbarClient from './NavbarClient';
import NavbarTherapist from './NavbarTherapist'; // Import different navbars
import NavbarIndividual from './NavbarIndividual';
import EmailSignup from './EmailSignup';
import Footer from './Footer';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './config/firebaseConfig'; // Firebase setup
import { doc, getDoc } from 'firebase/firestore';




const Homepage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [userRole, setUserRole] = useState(''); // State to track user role

  useEffect(() => {
    // Listen for changes to the user's login state
    const unsubscribe = onAuthStateChanged(auth, async user => {
      console.log("Auth State Changed. User:", user); // Log user object
      setIsLoggedIn(!!user); // Set to true if user exists, otherwise false
  
      if (user) {
        // Fetch user role from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
          console.log('Role :',userDoc.data().role);
        } else {
          setUserRole(''); // Or 'guest' if you prefer
        }
      } else {
        setUserRole(''); // Clear role if not logged in
      }
    });
  
    return () => unsubscribe();
  }, []);
  
  let NavbarComponent;
  if (isLoggedIn) {
    if (userRole === 'client') {
      NavbarComponent = <NavbarClient />;
    } else if (userRole === 'therapist') {
      NavbarComponent = <NavbarTherapist />;
    } else if (userRole === 'individual') {
      NavbarComponent = <NavbarIndividual />;
    } else {
      NavbarComponent = <Navbar />;
    }
  } else {
    // Default Navbar when not logged in
    NavbarComponent = <Navbar />;
  }

  return (
    <>
      {NavbarComponent}
      <div className='homepage-container'>
        <header className='homepage-header'>
          <h1>Welcome to OCD Serenity</h1>
          <h2>
            Your supportive companion on your journey to manage OCD
            <br />
          </h2>
          <div className='header-content'>
            <div className='text-content'>
              <p>
                We provide a compassionate and supportive space for individuals
                affected by OCD, offering resources for practical mental health
                solutions.
                <br />
                <br />
                Gain more insight into your progress and take charge of your
                journey to reduce anxiety and stress!
                <br />
                <br />
              </p>
            </div>
            <div className='image-content'>
              <img src={calmMindImage} alt='calmMind' />
            </div>
          </div>
          <div>
          <br/>
            <iframe
                width='560'
                height='315'
                src='https://www.youtube.com/embed/H9r3fSMYFbY?si=1L2FwFeXbhaf5ho-'
                title='YouTube video player'
                frameBorder='0' // Change this to camelCase
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                referrerPolicy='strict-origin-when-cross-origin' // Change this to camelCase as well
                allowFullScreen
              ></iframe>
          </div>
        </header>
        <div className='pray-container'>
          <h3>May You Find Peace And Strength On Your Healing Journey</h3>
        </div>
        <section className='homepage-header'>
          <h1>How We Can Help</h1>
          <p>
            OCD Serenity is designed to help you track and manage anxiety
            through insightful data and personalized progress tracking.
            <br />
            <br />
            Here’s what we offer:
          </p>
          <br />
          <p>
            ✅ <strong>Dashboard</strong>: The Dashboard is your central hub for
            tracking progress, celebrating milestones, and managing tools that
            support your OCD journey. The dashboard empowers you to keep
            everything organized in one place, making it simpler to observe
            patterns, celebrate successes, and stay on track with your mental
            health journey.
          </p>
          <br />
          <p>
            ✅ <strong>OCD News Feed</strong>: Our OCD News Feed keeps you
            informed with the latest research, treatments, and support resources
            for OCD. Curated from trusted sources, this feed provides up-to-date
            articles and insights to help you stay connected with the OCD
            community.
          </p>
          <br />
          <p>
            ✅ <strong>Trigger Tracker</strong>: Use this tool to track your OCD
            triggers, intrusive thoughts, compulsions, and the relief or
            distractions you experience. This tracker helps you recognize
            patterns in your thoughts and behaviors, making it easier to monitor
            your progress over time.
          </p>
          <br />
          <p>
            ✅ <strong>Mood Tracker</strong>: The Pixel Mood Tracker is a simple
            and interactive tool to help you track your daily mood throughout
            the year.
          </p>
          <br />
          <p>
            ✅ <strong>Exposure Hierarchy Builder</strong>: The Exposure
            Hierarchy Builder helps you tackle your fears and anxieties
            step-by-step. This tool is designed to support you in facing
            difficult situations gradually, helping to build resilience and
            reduce anxiety over time.
          </p>
          <br />
          <p>
            ✅ <strong>SUDS Tracker</strong>: The Subjective Units of Distress
            Scale (SUDS) is a simple self-report tool used to measure the
            intensity of distress or discomfort you're experiencing. SUDS allows
            you to express how much distress you’re feeling in any given
            situation, making it easier to track progress over time.
          </p>
          <br />
          <p>
            ✅ <strong>Journal</strong>: The Journal is a safe space for you to
            express your thoughts, feelings, and experiences. Use this tool to
            reflect on your day, track your progress, and celebrate your
            achievements on your mental health journey.
          </p>
          <br />
          <p>
          ✅ <strong>Reassurance Coupon</strong>:  
          This tool allows users to log each reassurance coupon given and view 
          their history over time, aiming to decrease reliance on reassurance. The feature 
          also includes printable coupons that can be shared with a support 
          person, who will answer a single reassurance question per coupon.
          </p>
        </section>
        <EmailSignup />
      </div>
      <Footer />
    </>
  );
};

export default Homepage;
