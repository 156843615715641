import React, { useState, useEffect } from 'react'; // Add useState import
import { Link } from 'react-router-dom';
import { useAudio } from './AudioContext'; // Import the context
import './Navbar.css'; // Import CSS for styling

function Navbar() {
  const { showAudioPlayer, handleMusicClick, stopMusic } = useAudio();

  return (
    <nav className='navbar'>
      <div className='logo'>
        <Link to='/'>OCD Serenity</Link>
      </div>
      <div className='navbar-text'>
        <ul className='nav-links'>
          <li>
            <Link to='/ocdnews'>📰 OCD News Feed</Link>
          </li>
          <li>
            <Link to='/agenda'>📅 OCD Agenda</Link>
          </li>
          <li className='menu-item'>
            <span style={{ padding: '10px' }}>🛠️ Tools</span>
            <ul className='submenu'>
              <li>
                <Link to='/trigger'>🎯 Trigger Tracker</Link>
              </li>
              <li>
                <Link to='/mood'>😊 Mood Tracker</Link>
              </li>
              <li>
                <Link to='/hierarchy-builder'>
                  🚀 Exposure Hierarchy Builder
                </Link>
              </li>
              <li>
                <Link to='/tools'>⏱️ SUDS Tracker</Link>
              </li>
              <li>
                <Link to='/sudsimport'>📥 SUDS Import</Link>
              </li>
              <li>
                <Link to='/coupon'>🎟️ Reassurance Coupon</Link>
              </li>
              <li>
              <button
                onClick={handleMusicClick}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '10px',
                }}
                onMouseEnter={(e) => e.target.style.backgroundColor = 'black'}
                onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
              >
                🎵 Relaxing Sound
              </button>
              </li>
              <li>
                <Link>✍️ Journaling (registered user)</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to='/dashboard'>📊 Dashboard</Link>
          </li>
          <li>
            <Link to='/pricing'>💰 Pricing</Link>
          </li>
          <li className='menu-item'>
            <span style={{ padding: '10px', marginLeft: '100px' }}>
              👥 Login/Register
            </span>
            <ul className='submenu'>
              <li>
                <Link to='/login'>👤 Login</Link>
              </li>
              <li>
                <Link to='/registerasclient'>👤 Register as Individual</Link>
              </li>
              <li>
                <Link to='/registerastherapist'>🧑‍⚕️ Register as Therapist</Link>
              </li>
            </ul>
          </li>
        </ul>
        {/* Include Stop Button */}
        {showAudioPlayer && (
          <button onClick={stopMusic} style={{ position: 'absolute', top: '100px', right: '10px' }}>
            Stop Music
          </button>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
