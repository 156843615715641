import React, { useState, useEffect } from 'react';
import './ExposureHierarchyBuilder.css';
import Navbar from './Navbar';
import Warning from './Warning';
import Footer from './Footer';

const saveToLocalStorage = data => {
  localStorage.setItem('exposureHierarchy', JSON.stringify(data));
};

const getFromLocalStorage = () => {
  const data = localStorage.getItem('exposureHierarchy');
  return data ? JSON.parse(data) : [];
};

const ExposureHierarchyBuilder = () => {
  const [exposures, setExposures] = useState(getFromLocalStorage());
  const [newExposure, setNewExposure] = useState('');
  const [distressLevel, setDistressLevel] = useState(0);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    saveToLocalStorage(exposures);
  }, [exposures]);

  const addExposure = () => {
    if (newExposure && distressLevel) {
      const newEntry = {
        id: Date.now(),
        title: newExposure,
        distress: distressLevel,
        completed: false,
      };
      setExposures([...exposures, newEntry]);
      setNewExposure('');
      setDistressLevel(0);
    }
  };

  const deleteExposure = id => {
    setExposures(exposures.filter(exposure => exposure.id !== id));
  };

  const editExposure = exposure => {
    setNewExposure(exposure.title);
    setDistressLevel(exposure.distress);
    setEditId(exposure.id);
  };

  const updateExposure = () => {
    setExposures(
      exposures.map(exposure =>
        exposure.id === editId
          ? { ...exposure, title: newExposure, distress: distressLevel }
          : exposure
      )
    );
    setEditId(null);
    setNewExposure('');
    setDistressLevel(0);
  };

  const toggleCompletion = id => {
    setExposures(
      exposures.map(exposure =>
        exposure.id === id
          ? { ...exposure, completed: !exposure.completed }
          : exposure
      )
    );
  };

  const completedCount = exposures.filter(
    exposure => exposure.completed
  ).length;
  const totalCount = exposures.length;

  return (
    <>
      <Navbar />
      <div className='container-exposure'>
        <Warning />
        <h1>Exposure Hierarchy Builder</h1>
        <p>
          The Exposure Hierarchy Builder helps you tackle your fears and
          anxieties step-by-step. Start by listing situations that cause
          distress, ranking them by how challenging they feel. As you work
          through each exposure, mark your progress and adjust as needed. This
          tool is designed to support you in facing difficult situations
          gradually, helping to build resilience and reduce anxiety over time.
        </p>

        <div>
          <input
            type='text'
            value={newExposure}
            onChange={e => setNewExposure(e.target.value)}
            placeholder='Enter an anxiety-inducing situation'
          />
          <input
            type='number'
            value={distressLevel}
            onChange={e => setDistressLevel(e.target.value)}
            placeholder='Distress level (1-10)'
          />
          {editId ? (
            <button onClick={updateExposure}>Update Exposure</button>
          ) : (
            <button onClick={addExposure}>Add Exposure</button>
          )}
        </div>

        <h3>Progress</h3>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '100%',
              backgroundColor: '#ddd',
              height: '20px',
              marginRight: '10px',
            }}
          >
            <div
              style={{
                width: `${(completedCount / totalCount) * 100}%`,
                height: '100%',
                backgroundColor: '#4caf50',
              }}
            ></div>
          </div>
          <span>
            {completedCount} / {totalCount} completed
          </span>
        </div>

        <h3>Exposure Hierarchy</h3>

        <h4>Incomplete Exposures</h4>
        <ul>
          {exposures
            .filter(exposure => !exposure.completed)
            .sort((a, b) => b.distress - a.distress)
            .map(exposure => (
              <li key={exposure.id}>
                <span>
                  {exposure.title} (Distress: {exposure.distress})
                </span>
                <button onClick={() => toggleCompletion(exposure.id)}>
                  Mark Complete
                </button>
                <button onClick={() => editExposure(exposure)}>Edit</button>
                <button onClick={() => deleteExposure(exposure.id)}>
                  Delete
                </button>
              </li>
            ))}
        </ul>

        <h4>Completed Exposures</h4>
        <ul>
          {exposures
            .filter(exposure => exposure.completed)
            .sort((a, b) => b.distress - a.distress)
            .map(exposure => (
              <li key={exposure.id} style={{ textDecoration: 'line-through' }}>
                <span>
                  {exposure.title} (Distress: {exposure.distress})
                </span>
                <button onClick={() => toggleCompletion(exposure.id)}>
                  Mark Incomplete
                </button>
                <button onClick={() => deleteExposure(exposure.id)}>
                  Delete
                </button>
              </li>
            ))}
        </ul>
      </div>

      <Footer />
    </>
  );
};

export default ExposureHierarchyBuilder;
