import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { auth, db } from './config/firebaseConfig';
import Navbar from './Navbar';
import NavbarClient from './NavbarClient';
import NavbarTherapist from './NavbarTherapist';
import NavbarIndividual from './NavbarIndividual';
import Footer from './Footer';
import './Coupon.css';
import barCode from './barcode.png';

const Coupon = () => {
  const [userRole, setUserRole] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [couponsGiven, setCouponsGiven] = useState(0);
  const [history, setHistory] = useState([]);

  // Check user role and set userId
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoggedIn(!!user);

      if (user) {
        setUserId(user.uid); // Store user ID
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const role = userDoc.data().role;
          setUserRole(role);
          console.log('User role:', role);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  // Load history from Firestore if user is logged in and has a role
  useEffect(() => {
    const fetchUserHistory = async () => {
      if (userId && userRole) {
        const userHistoryDoc = await getDoc(doc(db, 'couponHistory', userId));
        if (userHistoryDoc.exists()) {
          setHistory(userHistoryDoc.data().history || []);
        } else {
          setHistory([]); // No history available
        }
      } else {
        const savedHistory = JSON.parse(localStorage.getItem('couponHistory')) || [];
        setHistory(savedHistory);
      }
    };

    fetchUserHistory();
  }, [userId, userRole]);

  // Log coupons function
  const logCoupons = async () => {
    const newEntry = {
      date: new Date().toLocaleDateString(),
      coupons: couponsGiven,
    };
    const updatedHistory = [...history, newEntry];
    setHistory(updatedHistory);

    if (userId) {
      const userHistoryRef = doc(db, 'couponHistory', userId);
      await setDoc(
        userHistoryRef,
        { history: arrayUnion(newEntry) },
        { merge: true }
      );
    } else {
      localStorage.setItem('couponHistory', JSON.stringify(updatedHistory));
    }

    setCouponsGiven(0); // Reset count
  };

  const clearHistory = async () => {
    const confirmed = window.confirm(
      'Are you sure you want to clear all history? This action cannot be undone.'
    );

    if (confirmed) {
      if (userId) {
        const userHistoryRef = doc(db, 'couponHistory', userId);
        await updateDoc(userHistoryRef, { history: [] });
      } else {
        localStorage.removeItem('couponHistory');
      }
      setHistory([]);
    }
  };

  // Navbar component selection based on user role
  let NavbarComponent;
  if (userRole === 'client') {
    NavbarComponent = <NavbarClient />;
  } else if (userRole === 'therapist') {
    NavbarComponent = <NavbarTherapist />;
  } else if (userRole === 'individual') {
    NavbarComponent = <NavbarIndividual />;
  } else {
    NavbarComponent = <Navbar />;
  }

  return (
    <>
      {NavbarComponent}
      <div className='container-coupon'>
        <h1>Reassurance coupon</h1>
                <p style={{ textAlign: 'justify' }}>
          OCD Reassurance coupons can be used as part of Exposure Response
          Prevention (ERP) or as needed to support those dealing with OCD.
          Ideally a family member, friend, or loved one will determine how many
          are needed per week. The person needing reassurance will trade in a
          coupon for one reassurance seeking question to be answered. The goal
          is to reduce the number of coupons used per week until the person with
          OCD can start managing living with uncertainty.
        </p>
        <div className='container-coupon-leave'>
          <h1>REASSURANCE COUPON</h1>
          <div className='header-content'>
            <div className='text-content'>
              <h2>
                <span className='highlight'>1 minute for 1 question</span>
              </h2>
              <p>
                <b>One coupon daily or as suggested by your therapist.</b>
              </p>
            </div>
            <div className='image-content1'>
              <img src={barCode} alt='barcode' />
            </div>
          </div>
          <p>
            <b>
              This reassurance coupon gives you one reassurance-seeking question
              or validation request. The person you give this coupon to will
              answer one of your questions honestly and to the best of their
              ability. You may not ask additional questions or for the response
              to be elaborated on.
            </b>
          </p>
          <p>
            <b>
              Use your coupons responsibly. Remember, every time you ask for
              reassurance, your anxiety/OCD becomes stronger. The goal is to no
              longer need to seek reassurance at all.
            </b>
          </p>
        </div>
        <p style={{ textAlign: 'center' }}>
          ➡️{' '}
          <a
            href='https://www.ocdserenity.com/Reassurance-coupons.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Download printable coupons here
          </a>{' '}
          ⬅️
        </p>
        <div className='coupon-logging'>
          <h2>Log Coupons Given</h2>
          <p>Coupons given this week: {couponsGiven}</p>
          <button onClick={() => setCouponsGiven(couponsGiven + 1)}>
            + Given Coupons
          </button>
          <button onClick={logCoupons} disabled={couponsGiven === 0}>
            Log Weekly Coupons
          </button>
        </div>

        {/* Coupon History Section */}
        <div className='coupon-history'>
          <h2>Coupon History</h2>
          {history.length > 0 ? (
            <>
              <table className='history-table'>
                <thead>
                  <tr>
                    <th>Week</th>
                    <th>Coupons Given</th>
                  </tr>
                </thead>
                <tbody>
                  {history.map((entry, index) => (
                    <tr key={index}>
                      <td>{entry.date}</td>
                      <td>{entry.coupons}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button className='clear-history' onClick={clearHistory}>
                Clear History
              </button>
            </>
          ) : (
            <p>No history available.</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Coupon;
