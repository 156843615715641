import React, { useState, useEffect } from 'react';
import { auth, db } from './config/firebaseConfig';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  doc,
  getDoc,
  addDoc,
  collection,
  serverTimestamp,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import './LoginScreen.css';
import Navbar from './Navbar';
import Footer from './Footer';

// Initialize Stripe
const stripePromise = loadStripe(
  'pk_test_51QI6oqEIYpEP7XBnci71T3DcogYUilUiJxOQj7lw4ZgvdYcvvG6ZsAc4PtWKslEqMhxksoRSZCdojXfBrTc32yya00IOCBLmsv'
);

const TrialExpirationModal = ({
  isOpen,
  daysLeft,
  onClose,
  onUpgrade,
  trialEnded,
}) => {
  if (!isOpen) return null;

  return (
    <div className='trial-modal-overlay'>
      <div className='trial-modal-content'>
        <h2>
          {trialEnded
            ? 'Your free trial has ended.'
            : `Your free trial ends in ${daysLeft} day${
                daysLeft > 1 ? 's' : ''
              }`}
        </h2>
        <p>
          {trialEnded
            ? 'Please upgrade to continue access.'
            : 'Consider upgrading to continue access.'}
        </p>
        <button onClick={onClose}>Later</button>
        <button onClick={onUpgrade}>Upgrade now</button>
      </div>
    </div>
  );
};

const LoginScreen = ({ setTherapistId }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);
  const [userRole, setUserRole] = useState(''); // New state for storing user role
  const [trialEnded, setTrialEnded] = useState(false); // New state for trial status

  const [user, setUser] = useState(null); // New state for storing user
  const navigate = useNavigate();

  /* useEffect(() => {
    const savedEmail = localStorage.getItem('savedEmail');
    const savedPassword = localStorage.getItem('savedPassword');
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []); */

  const handleCloseModal = () => {
    setIsModalOpen(false);

    if (userRole === 'therapist') {
      navigate('/therapistdashboard'); // Redirect to therapist dashboard
    } else if (userRole === 'individual') {
      navigate('/client-dashboard'); // Redirect to client dashboard
    }
  };

  const handleUpgrade = async () => {
    setIsModalOpen(false);

    try {
      const response = await fetch('/api/create-checkout-session', {
        // Adjusted endpoint name to match new backend
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ role: userRole, userId: user.uid }), // Pass userId here
      });

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }

      // Initialize Stripe and redirect to the checkout page
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId, // Updated to use sessionId from the Checkout Session response
      });

      if (error) {
        console.error('Error redirecting to checkout:', error);
        alert('Failed to redirect to checkout. Please try again.');
      }
    } catch (error) {
      console.error('Error handling upgrade:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const logLoginEvent = async (email, role, eventType, note) => {
    try {
      await addDoc(collection(db, 'Logs'), {
        timestamp: serverTimestamp(),
        eventType,
        email,
        role,
        note,
      });
      console.log('Login event logged successfully');
    } catch (error) {
      console.error('Error logging login event:', error);
    }
  };

  const handleLogin = async e => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const loggedInUser = userCredential.user;
      setUser(loggedInUser); // Update user state

      /* if (rememberMe) {
        localStorage.setItem('savedEmail', email);
        localStorage.setItem('savedPassword', password);
      } else {
        localStorage.removeItem('savedEmail');
        localStorage.removeItem('savedPassword');
      }*/

      const userDocRef = doc(db, 'users', loggedInUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const userRole = userData.role;
        const userStatus = userData.status;
        const createdAt = userData.createdAt.toDate();
        const daysElapsed = Math.floor(
          (Date.now() - createdAt.getTime()) / (1000 * 60 * 60 * 24)
        );

        setUserRole(userRole); // Store the user role for later use

        // Log the login event in Firestore
        await logLoginEvent(loggedInUser.email, userRole, 'login', 'Logged in successfully!');

        if (userRole === 'therapist') {
          const trialPeriodDays = 30;
          const remainingDays = trialPeriodDays - daysElapsed;
          setDaysLeft(remainingDays);

          if (
            remainingDays <= 10 &&
            remainingDays > 0 &&
            userStatus === 'free'
          ) {
            setTherapistId(loggedInUser.uid);
            setIsModalOpen(true);
            setTrialEnded(false);
          } else if (remainingDays <= 0 && userStatus === 'free') {
            // alert('Your free trial has ended. Please upgrade to continue access.');
            setTherapistId(loggedInUser.uid);
            setIsModalOpen(true);
            setTrialEnded(true);
          } else if (userStatus === 'paid') {
            setTherapistId(loggedInUser.uid);
            navigate('/therapistdashboard');
          } else if (userStatus === 'cancelled') {
            alert('The subscription for this account has been cancelled');
            setTherapistId(loggedInUser.uid);
            setIsModalOpen(true);
            setTrialEnded(true);
          }else {
            setTherapistId(loggedInUser.uid);
            navigate('/therapistdashboard');
          }
        } else if (userRole === 'individual') {
          const trialPeriodDays = 14;
          const remainingDays = trialPeriodDays - daysElapsed;
          setDaysLeft(remainingDays);

          if (
            remainingDays <= 7 &&
            remainingDays > 0 &&
            userStatus === 'free'
          ) {
            // alert(`Your free trial ends in ${remainingDays} days. Consider upgrading to continue access.`);
            setIsModalOpen(true);
            setTrialEnded(false);
          } else if (remainingDays <= 0 && userStatus === 'free') {
            // alert('Your free trial has ended. Please upgrade to continue access.');
            setIsModalOpen(true);
            setTrialEnded(true);
          } else if (userStatus === 'paid') {
            navigate('/client-dashboard');
          }
          // navigate('/client-dashboard');
        } else if (userRole === 'client') {
          navigate('/client-dashboard');
        } else {
          throw new Error('User role not recognized.');
        }
      } else {
        throw new Error('User data not found.');
      }
    } catch (error) {
      
      console.error('Error logging in:', error);
      setError(
        'Failed to log in. Please check your credentials and try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setError('Please enter your email to reset the password.');
      return;
    }
    setMessage('');
    setError(null);

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Check your inbox.');
      
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError('Failed to send password reset email. Please try again.');
    }
  };

  return (
    <>
      <Navbar />
      <TrialExpirationModal
        isOpen={isModalOpen}
        daysLeft={daysLeft}
        onClose={handleCloseModal}
        onUpgrade={handleUpgrade}
      />
      <div className='login-screen'>
        <h2>Login</h2>
        <form onSubmit={handleLogin} className='login-form'>
          <label>
            Email:
            <input
              type='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </label>
          <label>
            Password:
            <input
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
          </label>

          {loading ? (
            <p>Loading your dashboard...</p>
          ) : (
            <button type='submit'>Log In</button>
          )}
          <button
            type='button'
            onClick={handlePasswordReset}
            className='reset-password-button'
          >
            Forgot Password?
          </button>
          {message && <p className='message'>{message}</p>}
          {error && <p className='error'>{error}</p>}
        </form>
      </div>
      <Footer />
    </>
  );
};

export default LoginScreen;
