// SUDSImport.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './SUDSImport.css';
import Navbar from './Navbar';
import Warning from './Warning';
import Footer from './Footer';

const SUDSImport = () => {
  const [timeLeft] = useState(0);
  const [intervalId] = useState(null);
  const [timeInterval, setTimeInterval] = useState(5); // New state for time interval
  const [importedAnxietyLevels, setImportedAnxietyLevels] = useState('');
  const [importedInterval, setImportedInterval] = useState('5'); // Default to 5 min
  const [importedRitual, setImportedRitual] = useState('');

  console.log(timeInterval);

  // Function to load settings from localStorage
  const loadSettings = () => {
    const savedSettings = JSON.parse(localStorage.getItem('settings'));
    if (savedSettings && savedSettings.interval) {
      setTimeInterval(savedSettings.interval);
    }
  };

  const fearTriggers = [
    'Adjusting volume levels to specific numbers',
    'Aligning furniture perfectly',
    'Arranging books in a specific order',
    'Arranging coins or currency notes',
    'Arranging items in a particular order',
    'Arranging pillows in a specific way',
    'Asking for constant reassurance',
    'Avoiding activities at certain times of day',
    'Avoiding certain colors',
    'Avoiding certain foods or drinks',
    'Avoiding certain phrases in conversations',
    'Avoiding certain seats in public places',
    'Avoiding certain shapes',
    'Avoiding certain words while speaking',
    'Avoiding cracks in walls or surfaces',
    'Avoiding cracks on the sidewalk',
    'Avoiding driving over certain areas of road',
    'Avoiding eye contact with certain objects',
    'Avoiding fabrics with specific textures',
    'Avoiding germs in public spaces',
    'Avoiding looking at specific numbers on clocks',
    'Avoiding numbers believed to be unlucky',
    'Avoiding odd or even numbers',
    'Avoiding sharp objects',
    'Avoiding sitting in certain chairs',
    'Avoiding specific numbers',
    'Avoiding specific people or locations',
    'Avoiding specific TV channels or shows',
    'Avoiding stepping on shadows',
    'Avoiding stepping on specific floor tiles',
    'Avoiding touching certain materials',
    'Avoiding using certain types of soap',
    'Checking appliances multiple times',
    'Checking bank statements repeatedly',
    'Checking belongings for damage',
    'Checking car tires repeatedly',
    'Checking clothes for dirt or stains',
    'Checking doors multiple times',
    'Checking drawers to ensure they are closed',
    'Checking email drafts multiple times',
    'Checking expiration dates obsessively',
    'Checking for bad luck symbols',
    'Checking for signs of illness excessively',
    'Checking pockets repeatedly for keys or phone',
    'Checking seatbelt lock multiple times',
    'Checking stove knobs multiple times',
    'Checking the alignment of frames on walls',
    'Checking the mailbox excessively',
    'Checking the placement of personal belongings',
    'Checking wallet or purse contents',
    'Cleaning glasses or lenses repeatedly',
    'Cleaning shoes before entering home',
    'Cleaning smartphone case obsessively',
    'Clearing browser history repeatedly',
    'Color-coding clothing or accessories',
    'Collecting unnecessary items',
    'Correcting others actions to fit personal standards',
    'Correcting others language mistakes',
    'Counting bites of food while eating',
    'Counting body movements',
    'Counting fingers on hands',
    'Counting letters in words mentally',
    'Counting objects or steps',
    'Counting steps on stairs mentally',
    'Counting steps while walking',
    'Counting while performing tasks',
    'Covering sharp corners with cushions',
    'Double-knotting shoelaces repeatedly',
    'Ensuring chairs are aligned with tables',
    'Ensuring curtains are perfectly straight',
    'Ensuring doors are closed in a specific way',
    'Ensuring symmetrical placement of objects',
    'Ensuring windows are perfectly aligned',
    'Erasing written text to avoid errors',
    'Flicking switches in specific patterns',
    'Fixating on exact times for activities',
    'Holding breath in certain situations',
    'Holding objects until they "feel right"',
    'Locking and unlocking car doors repeatedly',
    'Locking and unlocking doors',
    'Measuring distances between objects',
    'Mentally repeating words or phrases',
    'Moving objects back and forth',
    'Obsessively wiping dust off surfaces',
    'Organizing books alphabetically',
    'Organizing clothing by color',
    'Organizing desk or workspace meticulously',
    'Organizing items symmetrically',
    'Picturing specific images in the mind',
    'Placing grocery items in a fixed pattern',
    'Placing items on shelves in a particular sequence',
    'Placing shoes in specific positions',
    'Placing toilet paper in a particular way',
    'Pre-checking locks before leaving the house',
    'Pushing objects to align with edges',
    'Putting on clothes in a specific order',
    'Reading labels on products multiple times',
    'Reading signs or labels multiple times',
    'Re-assessing decisions for long periods',
    'Re-checking alarm clock settings',
    'Re-arranging items on shelves repeatedly',
    'Re-reading text for errors',
    'Removing perceived contaminants',
    'Repeating daily routines in the same order',
    'Repeating driving routes unnecessarily',
    'Repeating hand gestures for balance',
    'Repeating physical actions (e.g., opening and closing a door)',
    'Repeating prayers until they feel right',
    'Repeating sentences until they "feel right"',
    'Repeating specific prayers or chants',
    'Repeating steps when walking',
    'Rewriting sentences for perfection',
    'Separating items by color or type',
    'Storing items in specific ways',
    'Switching lights on and off a set number of times',
    'Tapping objects repeatedly',
    'Touching body parts in specific ways',
    'Touching objects a specific number of times',
    'Touching objects to undo "bad" thoughts',
    'Touching surfaces in specific sequences',
    'Touching walls while walking',
    'Turning knobs in specific sequences',
    'Turning lights on and off repeatedly',
    'Turning TV channels in specific orders',
    'Unplugging electrical devices unnecessarily',
    'Washing clothes unnecessarily',
    'Washing dishes multiple times',
    'Washing feet multiple times',
    'Washing hands repeatedly',
    'Wearing clothes in a specific order',
    'Wearing certain colors on specific days',
    'Wearing specific jewelry for protection',
    'Wiping bathroom surfaces constantly',
    'Wiping doorknobs with tissues',
    'Wiping electronic devices constantly',
    'Wiping hands excessively with sanitizers',
    'Wiping phone screen repeatedly',
  ];

  const storedRituals =
    JSON.parse(localStorage.getItem('rituals')) || fearTriggers;

  // const [rituals] = useState(storedRituals);

  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  const handleImportSubmit = () => {
    // Parse the anxiety levels from the input
    const levelsArray = importedAnxietyLevels
      .split(',')
      .map(level => parseInt(level.trim(), 10))
      .filter(level => !isNaN(level));

    if (importedRitual && levelsArray.length > 0) {
      const storedData = JSON.parse(localStorage.getItem(importedRitual)) || [];

      // Create new anxiety level objects based on the selected interval
      const newLevels = levelsArray.map((level, index) => ({
        time: index * parseInt(importedInterval, 10),
        level: level,
      }));

      const updatedData = [...storedData, newLevels];
      localStorage.setItem(importedRitual, JSON.stringify(updatedData));

      alert('Data imported successfully.');
      setImportedAnxietyLevels(''); // Clear the input
      goToDashboard();
    } else {
      alert('Please provide valid data and select a ritual.');
    }
  };

  useEffect(() => {
    loadSettings(); // Load settings on component mount
  }, []);

  useEffect(() => {
    if (timeLeft === 0 && intervalId) {
      clearInterval(intervalId);
    }
  }, [timeLeft, intervalId]);

  // ********************************************************************
  // ********************** ADD CUSTOM RIRTUALS *************************
  // ********************************************************************
  const [newRitual, setNewRitual] = useState('');
  const [rituals, setRituals] = useState(storedRituals);

  const addRitual = () => {
    if (newRitual.trim()) {
      const updatedRituals = [newRitual.trim(), ...rituals];
      setRituals(updatedRituals); // Update state with new rituals list
      localStorage.setItem('rituals', JSON.stringify(updatedRituals)); // Save updated rituals to local storage
      setNewRitual(''); // Clear input field after adding
      alert('Your ritual has been successfully added to the list! You can now select it from the rituals list.');
    } else {
      console.log('No ritual was added, input was empty.');
    }
  };

  // Save rituals to localStorage whenever the list changes
  useEffect(() => {
    localStorage.setItem('rituals', JSON.stringify(rituals));
  }, [rituals]);

  // *****************************************************************
  // ********************* RETURN ************************************
  // *****************************************************************
  return (
    <>
      <Navbar />
      <div className='import'>
        <Warning />
        <h1>SUDS Import</h1>
        <div className='import-header'>
          <p>
            Use this feature to quickly log multiple anxiety levels from your
            exposure exercises. Simply enter your levels of anxiety between 0 and 100 (SUDS
            scores) separated by commas, and select the interval at which these
            values were recorded. You can also choose the ritual practiced
            during the exercise.
          </p>
        </div>
        <p>
          Input your anxiety levels as comma-separated values (e.g., 85, 70,
          62).
        </p>

        <textarea
          value={importedAnxietyLevels}
          onChange={e => setImportedAnxietyLevels(e.target.value)}
          placeholder='Enter anxiety levels (e.g., 85, 70, 62)'
          rows='4'
        ></textarea>

        <div className='select-row'>
          <div className='select-container'>
            <label>
              Select Interval:
              <br />
              <br />
              <select
                value={importedInterval}
                onChange={e => setImportedInterval(e.target.value)}
              >
                <option value='3'>3 minutes</option>
                <option value='5'>5 minutes</option>
                <option value='10'>10 minutes</option>
                <option value='15'>15 minutes</option>
                <option value='20'>20 minutes</option>
              </select>
            </label>
          </div>

          <div className='select-container'>
            <label>
              Select Ritual:
              <br />
              <br />
              <select
                value={importedRitual}
                onChange={e => setImportedRitual(e.target.value)}
              >
                <option value='' disabled>
                  Select a ritual
                </option>

                {rituals.map((ritual, index) => (
                  <option key={index} value={ritual}>
                    {ritual}
                  </option>
                ))}
              </select>
            </label>
            <p>Or</p>
            <input
              type='text'
              placeholder='Add a new ritual...'
              value={newRitual}
              onChange={e => setNewRitual(e.target.value)}
            />
            <br />
            <br />
            <button onClick={addRitual} className='add-btn'>
              ➕ Add Ritual
            </button>
          </div>
        </div>
        <div className='button-container'>
          <button onClick={handleImportSubmit} className='import-button'>
            Save
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SUDSImport;
