import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './TherapistRegistration.css';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import {
  doc,
  setDoc,
  addDoc,
  collection,
  serverTimestamp,
  query, where, getDocs,
} from 'firebase/firestore';
import { auth, db } from './config/firebaseConfig'; // Import your firebase config file
import Navbar from './Navbar';
import Footer from './Footer';

const ClientRegistration = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error1, setError1] = useState(null);
  const [success1, setSuccess1] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [secretKey, setSecretKey] = useState('');
  const navigate = useNavigate(); // Initialize navigate

  const logEvent = async (email, role, eventType, note) => {
    try {
      await addDoc(collection(db, 'Logs'), {
        timestamp: serverTimestamp(),
        eventType,
        email,
        role,
        note,
      });
      console.log('Login event logged successfully');
    } catch (error) {
      console.error('Error logging login event:', error);
    }
  };

  const handleRegister = async e => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    // Check if password meets the minimum length requirement
    if (password.length < 6) {
      setError('Password must be at least 6 characters long.');
      setLoading(false);
      return;
    }

    try {
      // Open modal immediately after form submission to enter the secret key
      setModalOpen(true);
    } catch (error) {
      setError('Failed to open modal. Please try again.');
      console.error('Error opening modal:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSecretKeySubmit = async () => {
    setError(null); // Clear any previous error messages

    if (secretKey) {
      try {
        // Query Firestore to find the therapist with the provided secret key
        const therapistsQuery = query(
          collection(db, 'users'),
          where('therapistSecretKey', '==', secretKey)
        );
        const querySnapshot = await getDocs(therapistsQuery);

        if (!querySnapshot.empty) {
          const therapistDoc = querySnapshot.docs[0];
          const therapistId = therapistDoc.id;

          // Register user after secret key verification
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;

          // Add therapist data to Firestore with a therapist role
          await setDoc(doc(db, 'users', user.uid), {
            alias: '',
            email: email,
            role: 'client', // Now setting the role to client since linked with therapist
            therapistId: therapistId,
            comment: '',
            status: 'sponsored',
            createdAt: serverTimestamp(),
          });

          // Log the registration event in Firestore
          await logEvent(
            email,
            'client',
            'registration',
            'Client successfully registered and linked to therapist.'
          );

          setSuccess1('Therapist linked and registration complete!');
          setModalOpen(false); // Close modal on successful linking
          setEmail('');
          setPassword('');
          navigate('/client-dashboard'); // Redirect to client dashboard
        } else {
          // Display error message if the secret key is invalid
          setError1('Invalid Therapist Secret Key. Please try again or select "Skip" to create an Individual paid account.');
        }
      } catch (error) {
        console.error('Error verifying secret key:', error);
        setError1('Failed to verify Therapist Secret Key. Please try again.');
      }
    } else {
      setError1('Please enter a Therapist Secret Key or select "Skip" to create an Individual paid account.');
    }
  };

  const handleSkip = () => {
    // Skip the secret key process and register as an individual
    setModalOpen(false); // Close the modal
    registerAsIndividual(); // Register the user as an individual
  };

  const registerAsIndividual = async () => {
    try {
      // Register user as an individual with no therapist link
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Add user data to Firestore with individual role
      await setDoc(doc(db, 'users', user.uid), {
        alias: '',
        email: email,
        role: 'individual', // Setting the role to individual since no therapist
        therapistId: '',
        comment: '',
        status: 'free',
        createdAt: serverTimestamp(),
      });

      // Log the registration event in Firestore
      await logEvent(
        email,
        'individual',
        'registration',
        'Individual registered successfully without therapist link.'
      );

      setSuccess('Registration complete as an individual!');
      setEmail('');
      setPassword('');
      setModalOpen(false); // Close the modal
      navigate('/client-dashboard'); // Redirect to client dashboard
    } catch (error) {
      console.error('Error registering individual:', error);
      setError('Failed to register. Please try again.');
    }
  };

  return (
    <>
      <Navbar />
      <div className='therapist-registration'>
        <h2>Individual Registration</h2>
        <form onSubmit={handleRegister} className='registration-form'>
          <label>
            Email:
            <input
              type='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </label>
          <label>
            Password:
            <input
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
          </label>
          {loading ? (
            <p>Registering...</p>
          ) : (
            <button type='submit'>Register</button>
          )}
          {error && <p className='error'>{error}</p>}
          {success && <p className='success'>{success}</p>}
        </form>

        {/* Modal for entering Therapist Secret Key */}
        {modalOpen && (
          <div className='r-modal-overlay'>
            <div className='r-modal'>
              <h3>Enter Therapist Secret Key</h3>
              <p>
                If you have a Therapist Secret Key, enter it below to link your
                account to your therapist.
              </p>
              <input
                type='text'
                value={secretKey}
                onChange={e => setSecretKey(e.target.value)}
                placeholder='Therapist Secret Key'
              />
              <button onClick={handleSecretKeySubmit}>Continue</button>
              <button onClick={handleSkip}>Skip</button>
              {error1 && <p className='error'>{error1}</p>}
              {success1 && <p className='success'>{success1}</p>}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ClientRegistration;
