// Journal.js

import React, { useState, useEffect } from 'react';
import CustomModal from './CustomModal';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { onAuthStateChanged } from 'firebase/auth';
import moment from 'moment';
import Navbar from './Navbar';
import NavbarClient from './NavbarClient';
import NavbarTherapist from './NavbarTherapist';
import NavbarIndividual from './NavbarIndividual';
import Footer from './Footer';
import './Journal.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { db, auth } from './config/firebaseConfig';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { updateDoc, deleteField } from 'firebase/firestore';

const localizer = momentLocalizer(moment);

const Journal = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [journalText, setJournalText] = useState('');
  const [dayScore, setDayScore] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false); // Step 1: Loading state

  const [userRole, setUserRole] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check user authentication and role
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setIsLoggedIn(!!user);
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
          console.log('user role: ', userDoc.data().role);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const emojiOptions = {
    1: '😊',
    2: '🙂',
    3: '😟',
    4: '😫',
  };

  const userId = auth.currentUser?.uid;

  // Load journal entries when userId changes
  useEffect(() => {
    if (userId) {
      loadJournalEntries();
    }
  }, [userId]);

  const loadJournalEntries = async () => {
    try {
      const userDoc = await getDoc(doc(db, 'journals', userId));
      if (userDoc.exists()) {
        const journalEntries = userDoc.data().entries || {};
        const loadedEvents = Object.keys(journalEntries).map(date => {
          const entry = journalEntries[date];
          const emoji = emojiOptions[entry.score] || '😊';
          return {
            title: `${emoji} ${entry.text}`,
            start: new Date(date),
            end: new Date(date),
            text: entry.text,
            score: entry.score,
          };
        });
        setEvents(loadedEvents);
      }
    } catch (error) {
      console.error('Error loading journal entries:', error);
    } finally {
      setLoading(false); // Stop loading after data is fetched
    }
  };

  const openModal = date => {
    const entry = events.find(
      event => event.start.toDateString() === date.toDateString()
    );

    if (entry) {
      setSelectedDate(date);
      setJournalText(entry.text || '');
      setDayScore(entry.score || null);
    } else {
      // If there's no entry for that date, clear the modal
      setSelectedDate(date);
      setJournalText('');
      setDayScore(null);
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDate(null);
    setJournalText('');
    setDayScore(null);
  };

  const saveJournalEntry = async () => {
    const journalDate = selectedDate.toISOString();
    const newEntry = { text: journalText, score: dayScore };

    try {
      await setDoc(
        doc(db, 'journals', userId),
        { entries: { [journalDate]: newEntry } },
        { merge: true }
      );

      // Update events state with the new or edited entry
      setEvents(prevEvents => {
        const updatedEvents = prevEvents.filter(
          event => event.start.toDateString() !== selectedDate.toDateString()
        );
        const emoji = emojiOptions[dayScore] || '😊'; // Default emoji if score not found
        return [
          ...updatedEvents,
          {
            title: `${emoji} ${journalText}`, // Combine emoji and text
            start: new Date(journalDate),
            end: new Date(journalDate),
            text: journalText,
            score: dayScore,
          },
        ];
      });

      closeModal();
    } catch (error) {
      console.error('Error saving journal entry:', error);
    }
  };


  // Select Navbar based on user role
  let NavbarComponent;
  if (userRole === 'client') {
    NavbarComponent = <NavbarClient />;
  } else if (userRole === 'therapist') {
    NavbarComponent = <NavbarTherapist />;
  } else if (userRole === 'individual') {
    NavbarComponent = <NavbarIndividual />;
  } else {
    NavbarComponent = <Navbar />;
  }

  // ***************************************************************************
  // ********************************* RETURN **********************************
  // ***************************************************************************
  return (
    <>
      {NavbarComponent}
      {loading ? (
        <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
      ) : (
      <div className='container-journal '>
      
        <h1>Your Journal</h1>
        <p style={{ textAlign: 'justify' }}>
          The Journal allows you to easily document your thoughts and feelings
          on specific dates, helping to track emotional patterns over time. You
          can click on any date to open a modal where you can write your journal
          entry in the provided text area. If you want to view an existing
          entry, hover over a date to see the entry's text and emoji, and then
          click on that date to edit or add new information.
        </p>
        <p>
          This tool not only helps in reflecting on your daily experiences but
          also in identifying trends and triggers related to your emotional
          well-being.
        </p>
       
        <Calendar
          localizer={localizer}
          events={events}
          views={['month']}
          defaultView='month'
          onSelectSlot={({ start }) => openModal(start)}
          onSelectEvent={event => openModal(event.start)}
          selectable
          style={{ height: 500, margin: '50px' }}
        />

        <CustomModal isOpen={isModalOpen} onClose={closeModal}>
          <h3>
            Journal Entry for{' '}
            {selectedDate && moment(selectedDate).format('MMMM Do YYYY')}
          </h3>
          <textarea
            value={journalText}
            onChange={e => setJournalText(e.target.value)}
            placeholder='Write your thoughts and feelings...'
            rows='5'
            className='textarea'
          />

          <div className='emoji-container'>
            <p>Score your day:</p>
            {Object.entries(emojiOptions).map(([score, emoji]) => (
              <button
                key={score}
                className={`emoji-button ${
                  dayScore === parseInt(score) ? 'selected' : ''
                }`}
                onClick={() => setDayScore(parseInt(score))}
              >
                {emoji}
              </button>
            ))}
          </div>

          <div className='modal-buttons'>
            <button className='save-button' onClick={saveJournalEntry}>
              Save
            </button>
            <button className='cancel-button' onClick={closeModal}>
              Cancel
            </button>

          </div>
        </CustomModal>
        
      </div>
      )}
      <Footer />
    </>
  );
};

export default Journal;
