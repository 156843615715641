import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './Donate.css'; // Optional if you have styles for the TOS page
import calmMindImage from './calmMind-removebg-preview.png';

const Donate = () => {
  return (
    <>
      <Navbar />
      <div className='donate-page'>
            <h1>Donate</h1>
            <div className='quote-container'>
              <blockquote className='quote'>
                <p>
                  "Thank you for considering a donation to support our mission!
                  Your contribution will help us continue improving and
                  maintaining the SUDS tool to better serve those in need."
                </p>
                <footer>— Olivier Charles, Product Designer</footer>
              </blockquote>

              <img src={calmMindImage} alt='calmMind' />
            </div>

            <div className='impact-statement'>
              <h2>How Your Donation Helps</h2>
              <p>
                Your support directly contributes to ongoing development and
                research, ensuring that we can provide the best comprehensive
                support and resources for individuals managing
                Obsessive-Compulsive Disorder (OCD). We aim to empower users
                with the tools they need to effectively understand and manage
                their condition, improving their quality of life. <br />
                <br />
                More features and tools are coming soon...
              </p>
            </div>

            <div className='call-to-action'>
              <h2>Make a Difference</h2>
              {/* Embed PayPal Donate button */}
              <form
                action='https://www.paypal.com/donate'
                method='post'
                target='_top'
              >
                <input
                  type='hidden'
                  name='hosted_button_id'
                  value='RRQ4J6BCAYJ7Q'
                />
                <input
                  type='image'
                  width='200px'
                  src='https://www.paypalobjects.com/en_US/ES/i/btn/btn_donateCC_LG.gif'
                  border='0'
                  name='submit'
                  title='PayPal - The safer, easier way to pay online!'
                  alt='Donate with PayPal button'
                />
                <img
                  alt=''
                  border='0'
                  src='https://www.paypal.com/en_ES/i/scr/pixel.gif'
                  width='1'
                  height='1'
                />
              </form>
            </div>

            <div className='investor-message'>
              <h2>For Investors</h2>
              <p>
                We appreciate your interest in supporting our mission to enhance
                the lives of individuals managing Obsessive-Compulsive Disorder
                (OCD). If you're considering a partnership or investment
                opportunity, we would love to connect with you.
              </p>
              <p>
                Please reach out to us at{' '}
                <a href='mailto:investor@ocdserenity.com'>investor@ocdserenity.com</a>{' '}
                for more information.
              </p>
            </div>

            <div className='contact-info'>
              <h2>Questions?</h2>
              <p>
                Feel free to <a href='mailto:contact@ocdserenity.com'>Contact Us</a>{' '}
                with any questions.
              </p>
            </div>

            <div className='thank-you-message'>
              <p>Thank you for your generosity!</p>
            </div>
          </div>
     <Footer />
    </>
  );
};

export default Donate;
