import React, { useState, useEffect } from 'react';
import './OCDTriggerTracker.css'; // Import the CSS file
import Navbar from './Navbar';
import NavbarClient from './NavbarClient';
import NavbarTherapist from './NavbarTherapist'; // Import different navbars
import NavbarIndividual from './NavbarIndividual';
import Footer from './Footer';
import { db, auth } from './config/firebaseConfig'; // Import Firebase config
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const OCDTriggerTrackerClient = () => {
  const [trigger, setTrigger] = useState('');
  const [thought, setThought] = useState('');
  const [compulsion, setCompulsion] = useState('');
  const [relief, setRelief] = useState('');
  const [distress, setDistress] = useState('😄');
  const [data, setData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // Default sort order
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // ***********************************************************************************
  // ******************************* CHECK USER ROLE ***********************************
  // ***********************************************************************************
  const [userRole, setUserRole] = useState(''); // State to track user role
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  useEffect(() => {
    // Listen for changes to the user's login state
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setIsLoggedIn(!!user); // Set to true if user exists, otherwise false

      if (user) {
        // Fetch user role from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
          console.log('user role: ', userDoc.data().role);
        }
      }
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  // ****************************************************************
  // **************** Load user data on page load *******************
  // ****************************************************************

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, currentUser => {
      if (currentUser) {
        setUser(currentUser);
        loadTriggers(currentUser.uid);
      } else {
        setUser(null);
        setData([]); // Clear data if no user is authenticated
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, []);

  // ****************************************************************
  // *********** Fetch trigger data from Firebase Firestore *********
  // ****************************************************************

  const loadTriggers = async userId => {
    try {
      const docRef = doc(db, 'ocdTriggers', userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setData(docSnap.data().triggers || []);
      } else {
        console.log('No trigger data found.');
      }
    } catch (error) {
      console.error('Error loading triggers:', error);
    }
  };

  // ****************************************************************
  // ********** Save trigger data to Firebase Firestore *************
  // ****************************************************************

  const saveToFirestore = async updatedData => {
    if (!user) return;
    const docRef = doc(db, 'ocdTriggers', user.uid);
    try {
      await setDoc(docRef, { triggers: updatedData });
    } catch (error) {
      console.error('Error saving triggers:', error);
    }
  };

  // ****************************************************************
  // ************* Handle form submission (Add or Edit) *************
  // ****************************************************************

  const handleSubmit = e => {
    e.preventDefault();

    const newEntry = {
      date: new Date().toLocaleDateString(),
      trigger,
      thought,
      compulsion,
      relief,
      distress,
    };

    let updatedData;
    if (editIndex !== null) {
      updatedData = [...data];
      updatedData[editIndex] = newEntry;
      setEditIndex(null);
    } else {
      updatedData = [...data, newEntry];
    }

    setData(updatedData);
    saveToFirestore(updatedData);

    // Clear form fields
    setTrigger('');
    setThought('');
    setCompulsion('');
    setRelief('');
    setDistress('');
  };

  // ****************************************************************
  // ********************* Handle edit action ***********************
  // ****************************************************************

  const handleEdit = index => {
    const entry = data[index];
    setTrigger(entry.trigger);
    setThought(entry.thought);
    setCompulsion(entry.compulsion);
    setRelief(entry.relief);
    setDistress(entry.distress);
    setEditIndex(index);
  };

  // ****************************************************************
  // ******************* Handle delete action ***********************
  // ****************************************************************

  const handleDelete = index => {
    const updatedData = data.filter((_, i) => i !== index);
    setData(updatedData);
    saveToFirestore(updatedData);
  };

  // ****************************************************************
  // ************ Filter data based on search term ******************
  // ****************************************************************

  const filteredData = data.filter(entry =>
    entry.trigger.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // ****************************************************************
  // ************** Sort data by date or distress level *************
  // ****************************************************************

  const sortData = key => {
    const sortedData = [...data].sort((a, b) => {
      if (key === 'date') {
        return sortOrder === 'asc'
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      } else if (key === 'distress') {
        return sortOrder === 'asc'
          ? a.distress.localeCompare(b.distress)
          : b.distress.localeCompare(a.distress);
      }
      return 0;
    });

    setData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle sort order
  };

  //if (loading) {
  //  return <p>Loading Trigger data...</p>;
  //}

  // Select Navbar based on user role
  let NavbarComponent;
  if (userRole === 'client') {
    NavbarComponent = <NavbarClient />;
  } else if (userRole === 'therapist') {
    NavbarComponent = <NavbarTherapist />;
  } else if (userRole === 'individual') {
    NavbarComponent = <NavbarIndividual />;
  } else {
    NavbarComponent = <Navbar />;
  }

  // ****************************************************************
  // **************************** RETURN ****************************
  // ****************************************************************

  return (
    <>
       {NavbarComponent}
      <div className='container-trigger'>
        <h2>OCD Trigger Tracker</h2>
        <p className='description'>
          Use this tool to track your OCD triggers, intrusive thoughts,
          compulsions, and the relief or distractions you experience. Select the
          distress level that best reflects your feelings using the provided
          emoji scale. Each entry is automatically saved, and you can view your
          recorded triggers at any time. This tracker helps you recognize
          patterns in your thoughts and behaviors, making it easier to monitor
          your progress over time.
        </p>
        <form onSubmit={handleSubmit} className='tracker-form'>
          <div className='form-group'>
            <label>Suspected Trigger:</label>
            <input
              type='text'
              value={trigger}
              onChange={e => setTrigger(e.target.value)}
              required
            />
          </div>
          <div className='form-group'>
            <label>Thought:</label>
            <input
              type='text'
              value={thought}
              onChange={e => setThought(e.target.value)}
              required
            />
          </div>
          <div className='form-group'>
            <label>Compulsion:</label>
            <input
              type='text'
              value={compulsion}
              onChange={e => setCompulsion(e.target.value)}
              required
            />
          </div>
          <div className='form-group'>
            <label>Relief/Distraction:</label>
            <input
              type='text'
              value={relief}
              onChange={e => setRelief(e.target.value)}
              required
            />
          </div>
          <div className='form-group'>
            <label>Distress Severity:</label>
            <select
              value={distress}
              onChange={e => setDistress(e.target.value)}
              required
            >
              <option value='😄'>😄</option>
              <option value='🙂'>🙂</option>
              <option value='😐'>😐</option>
              <option value='😟'>😟</option>
              <option value='😢'>😢</option>
            </select>
          </div>
          <button type='submit' className='btn-submit'>
            {editIndex !== null ? 'Update' : 'Save'}
          </button>
        </form>

        <hr />
        <div className='search-sort'>
          <input
            type='text'
            placeholder='Search by trigger...'
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <button onClick={() => sortData('date')}>Sort by Date</button>
          <button onClick={() => sortData('distress')}>Sort by Distress</button>
        </div>

        <h3>Recorded Triggers</h3>
        {filteredData.length > 0 ? (
          <table className='tracker-table'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Trigger</th>
                <th>Thought</th>
                <th>Compulsion</th>
                <th>Relief</th>
                <th>Distress</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((entry, index) => (
                <tr key={index}>
                  <td>{entry.date}</td>
                  <td>{entry.trigger}</td>
                  <td>{entry.thought}</td>
                  <td>{entry.compulsion}</td>
                  <td>{entry.relief}</td>
                  <td style={{ fontSize: '30px' }}>{entry.distress}</td>
                  <td>
                    <button
                      onClick={() => handleEdit(index)}
                      className='btn-action'
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(index)}
                      className='btn-action'
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No triggers recorded yet.</p>
        )}
      </div>
      <Footer />
    </>
  );
};

export default OCDTriggerTrackerClient;
