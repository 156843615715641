import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Navbar from './Navbar';
import NavbarClient from './NavbarClient';
import NavbarTherapist from './NavbarTherapist'; // Import different navbars
import NavbarIndividual from './NavbarIndividual';
import Footer from './Footer';
import './AgendaCalendar.css';
import { onAuthStateChanged } from "firebase/auth";
import {auth, db} from './config/firebaseConfig'; // Firebase setup
import { doc, getDoc } from 'firebase/firestore';

const localizer = momentLocalizer(moment);

const AgendaCalendar = () => {
  
   const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
   const [userRole, setUserRole] = useState(''); // State to track user role
   const [loading, setLoading] = useState(true); // State to track loading status
   
  useEffect(() => {
    // Listen for changes to the user's login state
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setIsLoggedIn(!!user); // Set to true if user exists, otherwise false

      if (user) {
        // Fetch user role from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
          
        }
      }
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  // ***************************************************************************
  // ************************** Fetch the JSON data ****************************
  // ***************************************************************************
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchWebinars = async () => {
      try {
        const response = await fetch(
          'https://www.ocdserenity.com/webinars.json'
        );

        // Check if response is ok
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Parse the response as text first
        const text = await response.text();
        console.log('Raw response text:', text);

        // Now try parsing the text into JSON
        const data = JSON.parse(text);
        // console.log('Parsed JSON:', data);

        const formattedEvents = data.webinars.map(event => ({
          title: event.title,
          start: new Date(event.start),
          end: new Date(event.end),
          description: event.description,
          link: event.link,
        }));
        setEvents(formattedEvents);
      } catch (error) {
        console.error('Error fetching webinar data:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };

    fetchWebinars();

    // Check if user is logged in
    const checkLoginStatus = () => {
      // Replace with actual logic to check login status
      const userLoggedIn =
        localStorage.getItem('isLoggedIn') === 'true' || false;
      setIsLoggedIn(userLoggedIn);
    };

    checkLoginStatus();
  }, []);

  // ***************************************************************************
  // ****************************** Event Styling ******************************
  // ***************************************************************************
  const eventStyleGetter = event => {
    let backgroundColor = event.title.includes('Advanced') ? '#f57c00' : 'grey'; // Example logic
    let style = {
      backgroundColor,
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      display: 'block',
    };
    return {
      style: style,
    };
  };

  // Handle event click using onSelectEvent
  const handleSelectEvent = event => {
    alert(
      `Title: ${event.title}\n\nDescription:\n\n ${event.description}\n\nLink: ${event.link}`
    );
  };

  // ***************************************************************************
  // *********************** Custom Event Tooltip ******************************
  // ***************************************************************************
  const CustomEvent = ({ event }) => {
    return (
      <span>
        <strong>{event.title}</strong>
        <p>
          {moment(event.start).format('hh:mm A')} -{' '}
          {moment(event.end).format('hh:mm A')}
        </p>
      </span>
    );
  };

  const eventPropGetter = event => {
    return {
      onClick: () => {
        alert(
          `Title: ${event.title}\nDescription: ${event.description}\nLink: ${event.link}`
        );
      },
    };
  };

  // Select Navbar based on user role
  let NavbarComponent;
  if (userRole === 'client') {
    NavbarComponent = <NavbarClient />;
  } else if (userRole === 'therapist') {
    NavbarComponent = <NavbarTherapist />;
  } else if (userRole === 'individual') {
    NavbarComponent = <NavbarIndividual />;
  } else {
    NavbarComponent = <Navbar />;
  }


  // ***************************************************************************
  // ********************************* RETURN **********************************
  // ***************************************************************************
  return (
    <>
      {NavbarComponent}

      {loading ? (
        <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
      ) : (
      <div className='dashboard-agenda'>
        <Calendar
          className='custom-calendar'
          localizer={localizer}
          events={events}
          startAccessor='start'
          endAccessor='end'
          views={['month', 'agenda']} // Enable these views
          defaultView='month' // Default to month view
          eventPropGetter={eventStyleGetter} // Apply custom styling
          onSelectEvent={handleSelectEvent} // Handle event click
          components={{
            event: CustomEvent, // Custom tooltip for events
          }}
          style={{ height: 700, width: 1150 }}
        />
      </div>
      )}
      <Footer />
    </>
  );
};

export default AgendaCalendar;
