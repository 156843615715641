import React, { useState, useEffect } from 'react';
import './OCDTriggerTracker.css'; // Import the CSS file
import Navbar from './Navbar';
import Warning from './Warning';
import Footer from './Footer';

const OCDTriggerTracker = () => {
  const [trigger, setTrigger] = useState('');
  const [thought, setThought] = useState('');
  const [compulsion, setCompulsion] = useState('');
  const [relief, setRelief] = useState('');
  const [distress, setDistress] = useState('😄');
  const [data, setData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // Default sort order

  // Load data from localStorage on page load
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('ocdTriggers')) || [];
    setData(storedData);
  }, []);

  // Save to localStorage
  const saveToLocalStorage = (updatedData) => {
    localStorage.setItem('ocdTriggers', JSON.stringify(updatedData));
  };

  // Handle form submission (Add or Edit)
  const handleSubmit = (e) => {
    e.preventDefault();

    const newEntry = {
      date: new Date().toLocaleDateString(),
      trigger,
      thought,
      compulsion,
      relief,
      distress,
    };

    let updatedData;
    if (editIndex !== null) {
      updatedData = [...data];
      updatedData[editIndex] = newEntry;
      setEditIndex(null);
    } else {
      updatedData = [...data, newEntry];
    }

    setData(updatedData);
    saveToLocalStorage(updatedData);

    // Clear form fields
    setTrigger('');
    setThought('');
    setCompulsion('');
    setRelief('');
    setDistress('');
  };

  // Handle edit action
  const handleEdit = (index) => {
    const entry = data[index];
    setTrigger(entry.trigger);
    setThought(entry.thought);
    setCompulsion(entry.compulsion);
    setRelief(entry.relief);
    setDistress(entry.distress);
    setEditIndex(index);
  };

  // Handle delete action
  const handleDelete = (index) => {
    const updatedData = data.filter((_, i) => i !== index);
    setData(updatedData);
    saveToLocalStorage(updatedData);
  };

  // Filter data based on search term
  const filteredData = data.filter((entry) =>
    entry.trigger.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sort data by date or distress level
  const sortData = (key) => {
    const sortedData = [...data].sort((a, b) => {
      if (key === 'date') {
        return sortOrder === 'asc'
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      } else if (key === 'distress') {
        return sortOrder === 'asc'
          ? a.distress.localeCompare(b.distress)
          : b.distress.localeCompare(a.distress);
      }
      return 0;
    });

    setData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle sort order
  };

  return (
    <>
      <Navbar />
    <div className="container-trigger">
    <Warning />
      <h2>OCD Trigger Tracker</h2>
      <p className="description">
  Use this tool to track your OCD triggers, intrusive thoughts, compulsions, and the relief or distractions you experience. 
  Select the distress level that best reflects your feelings using the provided emoji scale. 
  Each entry is automatically saved, and you can view your recorded triggers at any time. 
  This tracker helps you recognize patterns in your thoughts and behaviors, making it easier to monitor your progress over time.
</p>
      <form onSubmit={handleSubmit} className="tracker-form">
        <div className="form-group">
          <label>Suspected Trigger:</label>
          <input
            type="text"
            value={trigger}
            onChange={(e) => setTrigger(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Thought:</label>
          <input
            type="text"
            value={thought}
            onChange={(e) => setThought(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Compulsion:</label>
          <input
            type="text"
            value={compulsion}
            onChange={(e) => setCompulsion(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Relief/Distraction:</label>
          <input
            type="text"
            value={relief}
            onChange={(e) => setRelief(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Distress Severity:</label>
          <select
            value={distress}
            onChange={(e) => setDistress(e.target.value)}
            required
          >
            
            <option value="😄">😄</option>
            <option value="🙂">🙂</option>
            <option value="😐">😐</option>
            <option value="😟">😟</option>
            <option value="😢">😢</option>
          </select>
        </div>
        <button type="submit" className="btn-submit">
          {editIndex !== null ? 'Update' : 'Save'}
        </button>
      </form>

      <hr/>
      <div className="search-sort">
      
        <input
          type="text"
          placeholder="Search by trigger..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={() => sortData('date')}>Sort by Date</button>
        <button onClick={() => sortData('distress')}>Sort by Distress</button>
      </div>

      <h3>Recorded Triggers</h3>
      {filteredData.length > 0 ? (
        <table className="tracker-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Trigger</th>
              <th>Thought</th>
              <th>Compulsion</th>
              <th>Relief</th>
              <th>Distress</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((entry, index) => (
              <tr key={index}>
                <td>{entry.date}</td>
                <td>{entry.trigger}</td>
                <td>{entry.thought}</td>
                <td>{entry.compulsion}</td>
                <td>{entry.relief}</td>
                <td style={{ fontSize: '30px' }}>{entry.distress}</td>
                <td>
                  <button onClick={() => handleEdit(index)} className="btn-action">
                    Edit
                  </button>
                  <button onClick={() => handleDelete(index)} className="btn-action">
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No triggers recorded yet.</p>
      )}
    </div>
          <Footer />
    </>
  );
};

export default OCDTriggerTracker;
