import React, { useState } from 'react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from './config/firebaseConfig';
import './MessageModal.css';

const MessageModal = ({ client, therapistId, onClose }) => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const sendMessage = async () => {
    if (!message.trim()) return;

    setLoading(true);
    setError(null);

    try {
      await addDoc(collection(db, 'messages'), {
        senderId: therapistId,
        receiverId: client.id,
        message,
        timestamp: serverTimestamp(),
         read: false,
      });
      alert('Message sent successfully!');
      setMessage('');
      onClose();
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="message-modal">
      <h2>Send Message to {client.alias}</h2>
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Write your message here..."
      ></textarea>
      {loading ? <p>Sending message...</p> : <button onClick={sendMessage}>Send</button>}
      {error && <p className="error">{error}</p>}
      <br/><br/>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

export default MessageModal;
