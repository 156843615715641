import React, { useState, useEffect } from 'react';
import './Settings.css'; // You can create a separate CSS file if needed
import Navbar from './Navbar';
import Footer from './Footer';

const Settings = () => {
  const [timeInterval, setTimeInterval] = useState(5); // Default to 5 minutes
  const [rituals, setRituals] = useState([]);
  const [newRitual, setNewRitual] = useState('');

  // Debugging helper
  const logLocalStorage = () => {
    console.log('Current Local Storage:', localStorage);
  };

  const loadSettings = () => {
    const savedSettings = JSON.parse(localStorage.getItem('settings'));
    if (savedSettings && savedSettings.interval) {
      setTimeInterval(savedSettings.interval);
    }
  };

  const saveSettings = newInterval => {
    const updatedSettings = { interval: newInterval };
    localStorage.setItem('settings', JSON.stringify(updatedSettings));
  };

  const clearAllData = () => {
    // Loop through all keys in localStorage
    Object.keys(localStorage).forEach(key => {
      // Remove the key if it's not 'settings' or 'rituals'
      if (key !== 'settings' && key !== 'rituals') {
        localStorage.removeItem(key);
      }
    });

    alert('All historical data cleared');
    logLocalStorage();
  };

  const deleteRitual = ritualToDelete => {
    const updatedRituals = rituals.filter(ritual => ritual !== ritualToDelete);
    if (updatedRituals.length > 0) {
      setRituals(updatedRituals);
    } else {
      console.log(
        'Attempted to delete all rituals, but preserving an empty list in memory.'
      );
      // Optionally, you could prevent setting empty arrays here if you don’t want to allow it
    }
  };

  const addRitual = () => {
    if (newRitual.trim()) {
      setRituals([...rituals, newRitual.trim()]);
      setNewRitual(''); // Clear input field after adding
    } else {
      console.log('No ritual was added, input was empty.');
    }
  };

  // Load rituals from localStorage
  useEffect(() => {
    const storedRituals = JSON.parse(localStorage.getItem('rituals')) || [];
    if (storedRituals.length > 0) {
      setRituals(storedRituals);
    }
    loadSettings();
  }, []);

  // Save rituals to localStorage whenever the list changes
  useEffect(() => {
    if (rituals.length > 0) {
      localStorage.setItem('rituals', JSON.stringify(rituals));
    } else {
      console.log('Rituals list is empty, skipping saving to localStorage.');
    }
  }, [rituals]);

  return (
    <>
      <Navbar />
      <div className='settings-page'>
        <div className='setting-item'>
          <label>Clear All Historical Data</label>
          <button onClick={clearAllData}>Clear Data</button>
        </div>

        <div className='setting-item'>
          <label>Time Interval Between Records (minutes)</label>
          <select
            value={timeInterval}
            onChange={e => {
              const newInterval = parseInt(e.target.value, 10);
              setTimeInterval(newInterval);
              saveSettings(newInterval);
            }}
          >
            <option value='3'>3 minutes</option>
            <option value='5'>5 minutes (default)</option>
            <option value='10'>10 minutes</option>
            <option value='15'>15 minutes</option>
            <option value='20'>20 minutes</option>
          </select>
        </div>

        <h3>Manage My List Of Rituals</h3>
        <div className='rituals-management'>
          <ul>
            {rituals.map((ritual, index) => (
              <li key={index} className='ritual-item'>
                {ritual}
                <button
                  onClick={() => deleteRitual(ritual)}
                  className='delete-btn'
                >
                  🗑️
                </button>
              </li>
            ))}
          </ul>

          <input
            type='text'
            placeholder='Add a new ritual...'
            value={newRitual}
            onChange={e => setNewRitual(e.target.value)}
          />
          <button onClick={addRitual} className='add-btn'>
            ➕ Add Ritual
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Settings;
