import React, { useState, useEffect } from 'react';
import './ExposureHierarchyBuilder.css';
import Navbar from './Navbar';
import NavbarClient from './NavbarClient';
import NavbarTherapist from './NavbarTherapist'; // Import different navbars
import NavbarIndividual from './NavbarIndividual';
import Footer from './Footer';
import { db, auth } from './config/firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const ExposureHierarchyBuilderClient = () => {
  const [exposures, setExposures] = useState([]);
  const [newExposure, setNewExposure] = useState('');
  const [distressLevel, setDistressLevel] = useState(0);
  const [editId, setEditId] = useState(null);
  const [user, setUser] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false); // New flag to track if data is loaded
  const navigate = useNavigate();

  // ***********************************************************************************
  // ******************************* CHECK USER ROLE ***********************************
  // ***********************************************************************************
  const [userRole, setUserRole] = useState(''); // State to track user role
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  useEffect(() => {
    // Listen for changes to the user's login state
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setIsLoggedIn(!!user); // Set to true if user exists, otherwise false

      if (user) {
        // Fetch user role from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
          console.log('user role: ', userDoc.data().role);
        }
      }
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  // Load exposures when the page loads and the user is authenticated
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, currentUser => {
      if (currentUser) {
        setUser(currentUser);
        loadExposures(currentUser.uid); // Load the exposures
      } else {
        setUser(null);
        setExposures([]); // Clear exposures if no user
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, []);

  // Function to load exposures from Firebase
  const loadExposures = async userId => {
    try {
      const docRef = doc(db, 'exposureHierarchy', userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setExposures(docSnap.data().exposures || []); // Load exposures into state
      }
      setDataLoaded(true); // Set the flag to true once data is loaded
    } catch (error) {
      console.error('Error loading exposures:', error);
      setDataLoaded(true); // Ensure flag is set even on error
    }
  };

  // Save exposures to Firebase, only if data has already been loaded
  useEffect(() => {
    if (user && dataLoaded) {
      saveExposures(user.uid, exposures);
    }
  }, [exposures, user, dataLoaded]);

  // Save exposures to Firebase
  const saveExposures = async (userId, exposuresData) => {
    try {
      const docRef = doc(db, 'exposureHierarchy', userId);
      await setDoc(docRef, { exposures: exposuresData });
    } catch (error) {
      console.error('Error saving exposures:', error);
    }
  };

  // Function to add a new exposure
  const addExposure = () => {
    if (newExposure && distressLevel) {
      const newEntry = {
        id: Date.now(),
        title: newExposure,
        distress: distressLevel,
        completed: false,
      };
      setExposures([...exposures, newEntry]);
      setNewExposure('');
      setDistressLevel(0);
    }
  };

  // Function to delete an exposure
  const deleteExposure = id => {
    setExposures(exposures.filter(exposure => exposure.id !== id));
  };

  // Function to edit an exposure
  const editExposure = exposure => {
    setNewExposure(exposure.title);
    setDistressLevel(exposure.distress);
    setEditId(exposure.id);
  };

  // Function to update an exposure
  const updateExposure = () => {
    setExposures(
      exposures.map(exposure =>
        exposure.id === editId
          ? { ...exposure, title: newExposure, distress: distressLevel }
          : exposure
      )
    );
    setEditId(null);
    setNewExposure('');
    setDistressLevel(0);
  };

  // Toggle completion status
  const toggleCompletion = id => {
    setExposures(
      exposures.map(exposure =>
        exposure.id === id
          ? { ...exposure, completed: !exposure.completed }
          : exposure
      )
    );
  };

  // Calculate progress
  const completedCount = exposures.filter(
    exposure => exposure.completed
  ).length;
  const totalCount = exposures.length;

  // Select Navbar based on user role
  let NavbarComponent;
  if (userRole === 'client') {
    NavbarComponent = <NavbarClient />;
  } else if (userRole === 'therapist') {
    NavbarComponent = <NavbarTherapist />;
  } else if (userRole === 'individual') {
    NavbarComponent = <NavbarIndividual />;
  } else {
    NavbarComponent = <Navbar />;
  }

  // *******************************************************************************************
  // ************************************* RETURN **********************************************
  // *******************************************************************************************
  return (
    <>
      {NavbarComponent}
      <div className='container-exposure'>
        <h1>Exposure Hierarchy Builder</h1>
        <p>
          The Exposure Hierarchy Builder helps you tackle your fears and
          anxieties step-by-step. Start by listing situations that cause
          distress, ranking them by how challenging they feel. As you work
          through each exposure, mark your progress and adjust as needed. This
          tool is designed to support you in facing difficult situations
          gradually, helping to build resilience and reduce anxiety over time.
        </p>

        {/* Add new exposure */}
        <div>
          <input
            type='text'
            value={newExposure}
            onChange={e => setNewExposure(e.target.value)}
            placeholder='Enter an anxiety-inducing situation'
          />
          <input
            type='number'
            value={distressLevel}
            onChange={e => setDistressLevel(e.target.value)}
            placeholder='Distress level (1-10)'
          />
          {editId ? (
            <button onClick={updateExposure}>Update Exposure</button>
          ) : (
            <button onClick={addExposure}>Add Exposure</button>
          )}
        </div>

        {/* Progress bar */}
        <h3>Progress</h3>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '100%',
              backgroundColor: '#ddd',
              height: '20px',
              marginRight: '10px',
            }}
          >
            <div
              style={{
                width: `${(completedCount / totalCount) * 100}%`,
                height: '100%',
                backgroundColor: '#4caf50',
              }}
            ></div>
          </div>
          <span>
            {completedCount} / {totalCount} completed
          </span>
        </div>

        {/* List of exposures */}
        <h3>Exposure Hierarchy</h3>
        <h4>Incomplete Exposures</h4>
        <ul>
          {exposures
            .filter(exposure => !exposure.completed)
            .sort((a, b) => b.distress - a.distress)
            .map(exposure => (
              <li key={exposure.id}>
                <span>
                  {exposure.title} (Distress: {exposure.distress})
                </span>
                <button onClick={() => toggleCompletion(exposure.id)}>
                  Mark Complete
                </button>
                <button onClick={() => editExposure(exposure)}>Edit</button>
                <button onClick={() => deleteExposure(exposure.id)}>
                  Delete
                </button>
              </li>
            ))}
        </ul>

        <h4>Completed Exposures</h4>
        <ul>
          {exposures
            .filter(exposure => exposure.completed)
            .sort((a, b) => b.distress - a.distress)
            .map(exposure => (
              <li key={exposure.id} style={{ textDecoration: 'line-through' }}>
                <span>
                  {exposure.title} (Distress: {exposure.distress})
                </span>
                <button onClick={() => toggleCompletion(exposure.id)}>
                  Mark Incomplete
                </button>
                <button onClick={() => deleteExposure(exposure.id)}>
                  Delete
                </button>
              </li>
            ))}
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default ExposureHierarchyBuilderClient;
