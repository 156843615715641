import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Import CSS for styling

function Footer() {
  return (
    <footer className='site-footer'>
      <div className='copyrigth'>
        <div className='urgency-notice'>
          <p>
            OCD Serenity content and apps do not provide medical advice or
            treatment. IF YOU ARE EXPERIENCING ANY KIND OF EMERGENCY, DIAL 911
            (US) OR 112 (EU) IMMEDIATELY, OR CALL THE NATIONAL SUICIDE
            PREVENTION LIFELINE. Find a list of hotlines in your country <a href='http://www.suicide.org/international-suicide-hotlines.html'>here</a>.
          </p>
          <hr/>
        </div>
        <p>
          &copy; {new Date().getFullYear()} All Rights Reserved, OCD
          Serenity&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link to="/tos">Terms of Service</Link> | <Link to="/privacy">Privacy policy</Link> | ✉️ <a href='mailto:contact@ocdserenity.com'>contact@ocdserenity.com</a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
