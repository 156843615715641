import React, { useState, useEffect } from 'react'; // Add useState import
import { Link, useNavigate } from 'react-router-dom';
import { useAudio } from './AudioContext'; // Import the context
import { signOut } from 'firebase/auth';
import './Navbar.css'; // Import CSS for styling
import { db, auth } from './config/firebaseConfig'; // Firebase setup
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Import Firestore functions


function NavbarIndividual() {
    const { showAudioPlayer, handleMusicClick, stopMusic } = useAudio();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isTrialPeriod, setIsTrialPeriod] = useState(false); // State to track if the user is in a trial period

      // Function to handle subscription cancellation
  const handleCancelSubscription = () => {
    const userId = auth.currentUser.uid; // Get the current user's ID
    const userDocRef = doc(db, 'users', userId); // Reference to the user's document in Firestore

    // Fetch the user's document from Firestore
    getDoc(userDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        const userStatus = docSnap.data().status; // Get the user's status
        if (userStatus === 'paid') {
          // If the user is a paid member, cancel the subscription
          console.log('Subscription cancelled');
          updateDoc(userDocRef, { status: 'cancelled' }); // Update the user's status to 'cancelled'
          alert('Subscription cancelled');
          handleLogout();


        } else {
          // If the user is not a paid member, log a message
          console.log('Cannot cancel subscription. User is not a paid member.');
        }
      } else {
        // If the user's document does not exist, log a message
        console.log('No such document!');
      }
    }).catch((error) => {
      // Log any errors that occur while fetching the document
      console.error('Error getting document:', error);
    });
    setShowCancelModal(false); // Close the cancel modal
  };

  // Function to handle account deletion
  const handleDeleteAccount = () => {
    const userId = auth.currentUser.uid; // Get the current user's ID
    const userDocRef = doc(db, 'users', userId); // Reference to the user's document in Firestore

    // Fetch the user's document from Firestore
    getDoc(userDocRef).then((docSnap) => {
      if (docSnap.exists()) {
        const userStatus = docSnap.data().status; // Get the user's status
        if (userStatus === 'paid') {
          // If the user is a paid member, cancel the subscription
          console.log('Subscription cancelled');
          updateDoc(userDocRef, { status: 'ACCOUNT TO BE DELETED' }); // Update the user's status to 'cancelled'
        
          handleLogout();


        } else {
          // If the user is not a paid member, log a message
          console.log('User is not a paid member.');
        }
      } else {
        // If the user's document does not exist, log a message
        console.log('No such document!');
      }
    }).catch((error) => {
      // Log any errors that occur while fetching the document
      console.error('Error getting document:', error);
    });
    setShowDeleteModal(false); // Close the delete modal
  };

    // ***************************************************************************
    // ********************************* LOGOUT **********************************
    // ***************************************************************************
    const navigate = useNavigate();
    const handleLogout = async () => {
      
      try {
        // Set 'isLoggedIn' to false in local storage
        //localStorage.setItem('isLoggedIn', 'false');
  
        // Sign out the user from Firebase
        await signOut(auth);
  
        // Navigate to the home or login page
        navigate('/');
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };

  return (
    <nav className='navbar'>
      <div className='logo'>
        <Link to='/'>OCD Serenity</Link>
      </div>
      <div className='navbar-text'>
        <ul className='nav-links'>
          <li>
            <Link to='/ocdnews'>📰 OCD News Feed</Link>
          </li>
          <li>
            <Link to='/agenda'>📅 OCD Agenda</Link>
          </li>
          <li className='menu-item'>
            <span style={{ padding: '10px' }}>🛠️ Tools</span>
            <ul className='submenu'>
              <li>
                <Link to='/client-trigger-tracker'>🎯 Trigger Tracker</Link>
              </li>
              <li>
                <Link to='/client-mood-tracker'>😊 Mood Tracker</Link>
              </li>
              <li>
                <Link to='/client-exposure-tracker'>
                  🚀 Exposure Hierarchy Builder
                </Link>
              </li>
              <li>
                <Link to='/client-suds-tracker'>⏱️ SUDS Tracker</Link>
              </li>
              <li>
                <Link to='/coupon'>🎟️ Reassurance Coupon</Link>
              </li>
              <li>
              <button
                onClick={handleMusicClick}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '10px',
                }}
                onMouseEnter={(e) => e.target.style.backgroundColor = 'black'}
                onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
              >
                🎵 Relaxing Sound
              </button>
              </li>
              <li>
                <Link to='/journal'>✍️ Journaling</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to='/client-dashboard'>📊 Dashboard</Link>
          </li>
          <li onClick={handleLogout}>
            <span>👤 Logout</span>
          </li>
          <li className='menu-item'>
            <span>⚙️ Settings</span>
            <ul className='submenu-1'>
              <li>
                <button
                  className='hover-red'
                  onClick={() => setShowCancelModal(true)}
                  disabled={isTrialPeriod}
                >
                  🚫 Cancel Subscription
                </button>
              </li>
              <li>
                <button
                  className='hover-red'
                  onClick={() => setShowDeleteModal(true)}
                >
                  🗑️ Delete Account
                </button>
              </li>
            </ul>
          </li>
        </ul>
        {/* Include Stop Button */}
        {showAudioPlayer && (
          <button onClick={stopMusic} style={{ position: 'absolute', top: '100px', right: '10px' }}>
            Stop Music
          </button>
        )}
        {showCancelModal && (
          <div className='modal'>
            <p>
              Are you sure you want to cancel your subscription? You will lose
              access to all premium features.
            </p>
            <button onClick={handleCancelSubscription}>Confirm</button>
            <button onClick={() => setShowCancelModal(false)}>Cancel</button>
            
          </div>
        )}
        {showDeleteModal && (
          <div className='modal'>
            <p>
              Are you sure you want to delete your account? You will lose access
              to all features and your data will be permanently deleted in the next 24 hours.
            </p>
            <button onClick={handleDeleteAccount}>Confirm</button>
            <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
            
          </div>
        )}
      </div>
    </nav>
  );
}

export default NavbarIndividual;
