import React, { useState, useEffect } from 'react';
import {
  Bar,
  BarChart,
  ComposedChart,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Dot,
  ReferenceLine,
} from 'recharts';
import { auth, db } from './config/firebaseConfig'; // Firebase setup
import {
  doc,
  getDoc,
  getDocs,
  updateDoc,
  deleteField,
} from 'firebase/firestore'; // Firestore functions
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import NavbarClient from './NavbarClient';
import Footer from './Footer';
import './Dashboard.css';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const ClientDashboard = () => {
  // *******************************************************************************************
  // ******************************* CHECK IF USER LOGGED IN ***********************************
  // *******************************************************************************************
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (!user) {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, []);

  // #################################################################
  // ####################### SUDS TRACKER ############################
  // #################################################################
  const [allHistoricalData, setAllHistoricalData] = useState([]);
  const [isSuds, setIsSuds] = useState(false);

  // *******************************************************************************************
  // ************************ Load all historical data from Firestore **************************
  // *******************************************************************************************
  const loadAllHistoricalDataFromFirestore = async () => {
    try {
      const user = auth.currentUser; // Get current authenticated user
      if (user) {
        const docRef = doc(db, 'ocdSuds', user.uid); // Reference to Firestore document
        const docSnap = await getDoc(docRef); // Get the document snapshot

        if (docSnap.exists()) {
          const data = docSnap.data();
          const formattedData = Object.keys(data).map(trigger => ({
            trigger,
            data: splitDataBySession(data[trigger]), // Split data by session
          }));

          setAllHistoricalData(formattedData);
          setIsSuds(true);

          if (formattedData.length <= 0) {
            setIsSuds(false);
          }
        } else {
          console.log('No SUDS data found for this user.');
          setIsSuds(false);
        }
      } else {
        console.log('User not authenticated SUDS.');
      }
    } catch (error) {
      console.error('Error fetching data from Firestore:', error);
    }
  };

  // Function to split data by session whenever time = 0
  const splitDataBySession = data => {
    const sessions = [];
    let currentSession = [];

    data.forEach(entry => {
      if (entry.time === 0 && currentSession.length > 0) {
        sessions.push(currentSession);
        currentSession = [];
      }
      currentSession.push(entry);
    });

    if (currentSession.length > 0) {
      sessions.push(currentSession); // Push the last session if it exists
    }

    return sessions;
  };

  const CustomDot = props => {
    const { cx, cy, payload } = props;
    let fill = 'lightblue';
    let r = 2;

    if (payload.level >= 80) {
      fill = 'red';
      r = 4;
    } else if (payload.level <= 20) {
      fill = 'green';
      r = 4;
    }

    return <Dot cx={cx} cy={cy} r={r} fill={fill} />;
  };

  const lineColors = [
    '#8884d8',
    '#82ca9d',
    '#ffc658',
    '#8dd1e1', // Existing colors
    '#d45087',
    '#f95d6a',
    '#ff7c43',
    '#a05195',
    '#665191',
    '#2f4b7c',
    '#4caf50',
    '#ff5722',
    '#607d8b',
    '#795548',
    '#9c27b0',
    '#673ab7',
    '#2196f3',
    '#3f51b5',
    '#00bcd4',
    '#009688',
    '#e91e63',
    '#c2185b',
    '#8bc34a',
    '#ffeb3b',
    '#ff9800',
    '#ff5722',
    '#00695c',
    '#cddc39',
    '#03a9f4',
    '#b0bec5',
    '#ff1744',
    '#f50057',
    '#d32f2f',
    '#c2185b',
  ];

  // *******************************************************************************************
  // **** calculate the trend, comparative, and progress summary for a given ritual's data *****
  // *******************************************************************************************

  const calculateSummary = ritualData => {
    const allLevels = ritualData.data.flatMap(session =>
      session.map(entry => entry.level)
    );

    // Calculate average anxiety level
    const avgLevel =
      allLevels.reduce((sum, level) => sum + level, 0) / allLevels.length;

    // Calculate highest and lowest anxiety levels
    const highestLevel = Math.max(...allLevels);
    const lowestLevel = Math.min(...allLevels);

    // Determine the trend
    let trend;

    if (ritualData.data.length === 1) {
      trend = '-';
    } else {
      trend =
        avgLevel > highestLevel
          ? 'up'
          : avgLevel < lowestLevel
          ? 'down'
          : 'stable';
    }

    // Comparative analysis (simple comparison with the first session's average)
    const initialSessionAvg =
      ritualData.data[0].reduce((sum, entry) => sum + entry.level, 0) /
      ritualData.data[0].length;

    let comparison;
    let progressPercentage;
    let milestoneReached;
    if (ritualData.data.length === 1) {
      comparison = ' (2 sessions needed!)';
      progressPercentage = 0;
    } else {
      comparison = avgLevel < initialSessionAvg ? 'Improved' : 'Worsened';
      progressPercentage = ((highestLevel - avgLevel) / highestLevel) * 100;
      milestoneReached =
        ritualData.data.length >= 3 &&
        allLevels.slice(-3).every(level => level < avgLevel);
    }

    return {
      avgLevel: avgLevel.toFixed(2),
      trend,
      comparison,
      highestLevel,
      lowestLevel,
      progressPercentage: progressPercentage.toFixed(2),
      milestoneReached,
    };
  };

  // ****************************************************************
  // ******* Load all historical data on component mount ************
  // ****************************************************************

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (!user) {
        navigate('/login');
      }
      if (user) {
        loadAllHistoricalDataFromFirestore();
      }
    });
    return () => unsubscribe();
    
  }, []);

  const handleDelete = async trigger => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete data for ${trigger}?`
    );
    if (!confirmDelete) {
      return; // Exit the function if the user cancels
    }
    try {
      const docRef = doc(db, 'ocdSuds', user.uid);
      await updateDoc(docRef, {
        [trigger]: deleteField(),
      });
      setAllHistoricalData(prevData =>
        prevData.filter(data => data.trigger !== trigger)
      );
      console.log(`${trigger} data deleted successfully.`);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  // #################################################################
  // ##################### TRIGGER TRACKER ###########################
  // #################################################################

  const [dataTrigger, setDataTrigger] = useState([]);
  const [averageDistress, setAverageDistress] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [topCommonTriggers, setTopCommonTriggers] = useState([]);
  const [sortedCompulsions, setSortedCompulsions] = useState([]);
  const [averageDistressLast7Days, setAverageDistressLast7Days] = useState(0);
  const [compulsionReliefRatio, setCompulsionReliefRatio] = useState(0);
  const [isTrigger, setIsTrigger] = useState(false);
  const distressLevels = {
    '😄': 1,
    '🙂': 2,
    '😐': 3,
    '😟': 4,
    '😢': 5,
  };

  const distressEmojis = Object.fromEntries(
    Object.entries(distressLevels).map(([emoji, level]) => [level, emoji])
  );

  useEffect(() => {
    // Function to fetch data from Firestore
    const fetchTriggerData = async () => {
      const user = auth.currentUser; // Get current authenticated user
      if (user) {
        try {
          const docRef = doc(db, 'ocdTriggers', user.uid); // Reference to user's trigger data in Firestore
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const fetchedData = docSnap.data().triggers || [];
            setDataTrigger(fetchedData);
            calculateStatistics(fetchedData); // Call function to calculate chart data and stats
            setIsTrigger(true);
            if (fetchedData.length > 0) {
              calculateStatistics(fetchedData); // Call function to calculate chart data and stats
              setIsTrigger(true);
              console.log('TRIGGER IS TRUE');
            } else {
              setIsTrigger(false);
              console.log('TRIGGER IS FALSE');
            }
          } else {
            console.log('No trigger data found for this user.');
            setIsTrigger(false);
          }
        } catch (error) {
          console.error('Error fetching trigger data from Firestore:', error);
        }
      } else {
        console.log('User not authenticated TRIGGER');
      }
    };

const unsubscribe = onAuthStateChanged(auth, user => {
      if (!user) {
        navigate('/login');
      }
      if (user) {
        fetchTriggerData(); // Call function to fetch data on component load
      }
    });
    return () => unsubscribe();


    
  }, []);

  // Function to calculate statistics from fetched data
  const calculateStatistics = data => {
    // Calculate average distress level
    const avgDistress =
      data.length > 0
        ? data.reduce((sum, entry) => sum + distressLevels[entry.distress], 0) /
          data.length
        : 0;
    setAverageDistress(avgDistress.toFixed(2));

    // Calculate data for the chart
    const chartData = data.reduce((acc, entry, index) => {
      const distressLevel = distressLevels[entry.distress] || 0;
      const total = acc.reduce((sum, data) => sum + data.distressLevel, 0);
      const averageDistress = ((total + distressLevel) / (index + 1)).toFixed(
        2
      );

      acc.push({
        entryNumber: index + 1,
        distressLevel,
        averageDistress,
      });

      return acc;
    }, []);
    setChartData(chartData);

    // Calculate trigger frequency
    const triggerFrequency = data.reduce((acc, entry) => {
      acc[entry.trigger] = (acc[entry.trigger] || 0) + 1;
      return acc;
    }, {});

    const topCommonTriggers = Object.entries(triggerFrequency)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 3)
      .map(([trigger]) => trigger);
    setTopCommonTriggers(topCommonTriggers);

    // Calculate compulsion frequency
    const compulsionFrequency = data.reduce((acc, entry) => {
      acc[entry.compulsion] = (acc[entry.compulsion] || 0) + 1;
      return acc;
    }, {});

    const sortedCompulsions = Object.entries(compulsionFrequency)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 3);
    setSortedCompulsions(sortedCompulsions);

    // Calculate distress over the last 7 days
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    const recentData = data.filter(entry => new Date(entry.date) >= oneWeekAgo);
    const avgDistressLast7Days =
      recentData.length > 0
        ? recentData.reduce(
            (sum, entry) => sum + distressLevels[entry.distress],
            0
          ) / recentData.length
        : 0;
    setAverageDistressLast7Days(avgDistressLast7Days.toFixed(1));

    // Calculate compulsion/relief ratio
    const totalCompulsions = data.filter(entry => entry.compulsion).length;
    const totalReliefs = data.filter(entry => entry.relief).length;
    const ratio =
      totalCompulsions > 0 ? (totalReliefs / totalCompulsions).toFixed(2) : 0;
    setCompulsionReliefRatio(ratio);
  };

  // ######################################################################
  // ######################### MOOD TRACKER ###############################
  // ######################################################################

  const [moodData, setMoodData] = useState({});
  const [loading, setLoading] = useState(true);
  const user = auth.currentUser; // Get the logged-in user
  const [isEmojis, setIsEmojis] = useState(false); // Initialize isEmojis state

  // Months to display (12 months)
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Emoji map for moods
  const moodEmojis = {
    1: '😊',
    2: '🙂',
    3: '😟',
    4: '😫',
  };

  useEffect(() => {
    const fetchMoodData = async () => {
      if (user) {
        const docRef = doc(db, 'moodTracker', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();

          if (Object.keys(data).length > 0) {
            setMoodData(data);
            setIsEmojis(true); // Set to true if data exists and is non-empty
          } else {
            console.log('Mood data is empty');
            setIsEmojis(false); // Set to false if data is empty
          }
        } else {
          console.log('No mood data found!');
          setIsEmojis(false); // Set to false if no document exists
        }
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, user => {
      if (!user) {
        navigate('/login');
      }
      if (user) {
          fetchMoodData();
          loadExposures(user.uid);
      }
    });
    return () => unsubscribe();


  }, [user]);

  // ****************************************************************
  // ************ Render the grid with days and months **************
  // ****************************************************************
  const renderGrid = () => {
    return months.map((month, monthIndex) => (
      <div key={month} className='month-row'>
        {/* Month label */}
        <div className='month-label'>{month}</div>
        <div className='days-row'>
          {Array.from({ length: 31 }, (_, day) => (
            <div
              key={day + 1}
              className={`grid-cell mood-${
                moodData[`${monthIndex}-${day + 1}`] || 0
              }`} // Apply mood class
            >
              {moodEmojis[moodData[`${monthIndex}-${day + 1}`]] || ''}
            </div>
          ))}
        </div>
      </div>
    ));
  };

  // ****************************************************************
  // ************* Calculate percentages for each emoji *************
  // ****************************************************************
  const calculateEmojiPercentages = () => {
    const totalEntries = Object.values(moodData).filter(
      value => value !== null
    ).length;
    if (totalEntries === 0) return {};

    const emojiCounts = Object.values(moodData).reduce((counts, mood) => {
      counts[mood] = (counts[mood] || 0) + 1;
      return counts;
    }, {});

    // Calculate percentage for each emoji
    const emojiPercentages = {};
    for (const mood in moodEmojis) {
      const count = emojiCounts[mood] || 0;
      emojiPercentages[mood] = ((count / totalEntries) * 100).toFixed(1);
    }

    return emojiPercentages;
  };

  const emojiPercentages = calculateEmojiPercentages();

  // ####################################################################################
  // ############################ EXPOSURE HIERARCHY BUILDER ############################
  // ####################################################################################
  const [exposures, setExposures] = useState([]);
  const [isExposure, setIsExposure] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false); // Add this line
  const [couponData, setCouponData] = useState([]);
  const [userId, setUserId] = useState(null);

  // Set userId based on the authenticated user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setUserId(user.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  // Fetch coupon data for the specific user
  useEffect(() => {
    const fetchCouponData = async () => {
      if (!userId) return;

      try {
        const userDocRef = doc(db, 'couponHistory', userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const data = userDoc.data().history || [];
          setCouponData(data);
        } else {
          console.log('No coupon data found for this user.');
        }
      } catch (error) {
        console.error('Error fetching coupon data:', error);
      }
    };

    fetchCouponData();
  }, [userId]);

  // Function to load exposures from Firebase
  const loadExposures = async userId => {
    try {
      const docRef = doc(db, 'exposureHierarchy', userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        /* setExposures(docSnap.data().exposures || []); // Load exposures into state
        setIsExposure(true);*/

        /*if (Object.keys(data).exposures.length > 0) {
          //console.log('Exposure length :', Object.keys(data).exposures.length);
          setExposures(docSnap.data().exposures || []); // Load exposures into state
          setIsExposure(true);
        }*/

        //if (Object.keys(data).triggers.length > 0) {
        const fetchedData1 = docSnap.data().exposures || [];
        setExposures(fetchedData1);

        setIsExposure(true);

        if (fetchedData1.length > 0) {
          setIsExposure(true);
        } else {
          setIsExposure(false);
        }
        //}
      }
      setDataLoaded(true); // Set the flag to true after loading data
    } catch (error) {
      console.error('Error loading exposures:', error);
      setDataLoaded(true); // Ensure flag is set even if there’s an error
    }
  };

  // ****************************************************************
  // *********************** Calculate statistics *******************
  // ****************************************************************
  const totalExposures = exposures.length;
  const completedExposures = exposures.filter(
    exposure => exposure.completed
  ).length;
  const incompleteExposures = totalExposures - completedExposures;
  const completionRate = totalExposures
    ? ((completedExposures / totalExposures) * 100).toFixed(1)
    : 0;

  const averageDistressLevel = exposures.length
    ? (
        exposures.reduce(
          (sum, exposure) => sum + Number(exposure.distress),
          0
        ) / exposures.length
      ).toFixed(1)
    : 0;

  const distressReductionOverTime = exposures
    .filter(exposure => exposure.completed && exposure.initialDistress)
    .reduce(
      (sum, exposure) => sum + (exposure.initialDistress - exposure.distress),
      0
    );

  // ################################################################
  // ############################ RETURN ############################
  // ################################################################
  return (
    <>
      <div class='mywraper'>
        <NavbarClient />
        <main>
          {/* ************************************************************************** */}
          {/* ***************** CHECK IF THERE ARE STAT TO DISPLAY ********************* */}
          {/* ************************************************************************** */}
          {(() => {
            if (!(isExposure || isEmojis || isTrigger || isSuds)) {
              return (
                <div className='dashboard'>
                  <p>
                    No data to display! Use the tools trackers to display here
                    your statistics.
                  </p>
                </div>
              );
            }
          })()}

          {/* **************************************************** */}
          {/* ****************** Coupon Tracker******************** */}
          {/* **************************************************** */}
          {(() => {
            const isValidData =
              couponData && Array.isArray(couponData) && couponData.length > 0;

            const graphData = couponData.map((entry, index) => ({
              date: `Week ${index + 1}`,
              coupons: entry.coupons,
            }));

            // Calculate statistics
            const totalCouponsGiven = couponData.reduce(
              (total, entry) => total + entry.coupons,
              0
            );
            const totalWeeksTracked = couponData.length;
            const averageCouponsPerWeek = totalWeeksTracked
              ? totalCouponsGiven / totalWeeksTracked
              : 0;
            const maxCouponsInAWeek = Math.max(
              ...couponData.map(entry => entry.coupons)
            );
            const minCouponsInAWeek = Math.min(
              ...couponData.map(entry => entry.coupons)
            );
            const lastWeekCoupons =
              couponData[totalWeeksTracked - 1]?.coupons || 0;

            return isValidData ? (
              <div className='dashboard'>
                <h2 className='dashboard-title'>Reassurance Coupon Tracker</h2>

                {/* Coupon Graph */}
                <div className='coupon-graph'>
                  <h3>Reassurance Coupons Given</h3>
                  <ResponsiveContainer width='100%' height={300}>
                    <BarChart data={graphData}>
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis dataKey='date' />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey='coupons' fill='#8884d8' />
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                {/* Statistics */}
                <div
                  className='container-trigger'
                  style={{
                    maxWidth: '400px',
                    borderRadius: '8px',
                    backgroundColor: '#1e1e1e',
                    boxShadow: '0 20px 20px rgba(0, 0, 0, 0.1)',
                    marginTop: '0px',
                  }}
                >
                  <p>
                    <strong>Total Coupons Given:</strong> {totalCouponsGiven}
                  </p>
                  <p>
                    <strong>Average Coupons Per Week:</strong>{' '}
                    {averageCouponsPerWeek.toFixed(2)}
                  </p>
                  <p>
                    <strong>Maximum Coupons in a Week:</strong>{' '}
                    {maxCouponsInAWeek}
                  </p>
                  <p>
                    <strong>Minimum Coupons in a Week:</strong>{' '}
                    {minCouponsInAWeek}
                  </p>
                  <p>
                    <strong>Total Weeks Tracked:</strong> {totalWeeksTracked}
                  </p>
                  <p>
                    <strong>Last Week's Coupons:</strong> {lastWeekCoupons}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            );
          })()}

          {/* **************************************************************** */}
          {/* *************** EXPOSURE HIERARCHY BUILDER ********************* */}
          {/* **************************************************************** */}
          {(() => {
            return isExposure ? (
              <div className='dashboard'>
                <h2 className='dashboard-title'>Exposure Hierarchy Builder</h2>
                <div className='statistics-grid'>
                  <div className='statistics-item'>
                    <p>
                      <strong>Total Number of Exposures:</strong>
                      <br />
                      <br />
                      <span style={{ fontSize: '60px', fontWeight: 'bold' }}>
                        {' '}
                        {totalExposures}
                      </span>
                    </p>
                  </div>
                  <div className='statistics-item'>
                    <p>
                      <strong>Completed vs. Incomplete Exposures:</strong>
                      <br />
                      <br />
                      <span style={{ fontSize: '25px', fontWeight: 'bold' }}>
                        {completedExposures} vs {incompleteExposures}
                      </span>
                    </p>
                  </div>
                  <div className='statistics-item'>
                    <p>
                      <strong>Average Distress Level:</strong>
                      <br />
                      <br />
                      <span style={{ fontSize: '60px', fontWeight: 'bold' }}>
                        {' '}
                        {averageDistressLevel}
                      </span>
                    </p>
                  </div>
                  <div className='statistics-item'>
                    <p>
                      <strong>Distress Reduction Over Time:</strong>
                      <br />
                      <br />
                      <span style={{ fontSize: '60px', fontWeight: 'bold' }}>
                        {distressReductionOverTime}
                      </span>
                    </p>
                  </div>
                  <div className='statistics-item'>
                    <p>
                      <strong>Exposure Completion Rate:</strong>
                      <br />
                      <br />
                      <br />
                      <span style={{ fontSize: '30px', fontWeight: 'bold' }}>
                        {' '}
                        {completionRate}%
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            );
          })()}

          {/* **************************************************** */}
          {/* ****************** MOOD TRACKER ******************** */}
          {/* **************************************************** */}
          {(() => {
            return isEmojis ? (
              <div className='dashboard'>
                <h2 className='dashboard-title'>Mood Tracker</h2>
                {/* Display emoji percentages */}
                <div className='emoji-percentages'>
                  {Object.keys(moodEmojis).map(mood => (
                    <div key={mood}>
                      {moodEmojis[mood]}: {emojiPercentages[mood] || 0}%
                    </div>
                  ))}
                </div>
                <div className='container1-mood'>
                  <div className='calendar-grid'>{renderGrid()}</div>
                </div>
              </div>
            ) : (
              <></>
            );
          })()}

          {/* **************************************************** */}
          {/* *************** TRIGGER TRACKER ******************** */}
          {/* **************************************************** */}
          {(() => {
            return isTrigger ? (
              <div className='dashboard'>
                <h2 className='dashboard-title'>Trigger Tracker</h2>
                <div
                  className='container-trigger'
                  style={{
                    maxWidth: '400px',
                    borderRadius: '8px',
                    backgroundColor: '#1e1e1e',
                    boxShadow: '0 20px 20px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <p>
                    <strong>Distress Level Trend (Last 7 Days):</strong>{' '}
                    {averageDistressLast7Days}/5
                  </p>
                  <p>
                    <strong>Compulsion to Relief Ratio:</strong>{' '}
                    {compulsionReliefRatio}
                  </p>

                  <p>
                    <strong>Top 3 Most Common Triggers:</strong>
                  </p>
                  <ol>
                    {topCommonTriggers.length > 0 ? (
                      topCommonTriggers.map((trigger, index) => (
                        <li key={index}>{trigger}</li>
                      ))
                    ) : (
                      <li>No triggers recorded.</li>
                    )}
                  </ol>
                  <p>
                    <strong>Top 3 Most Frequent Compulsions:</strong>
                  </p>
                  <ol>
                    {sortedCompulsions.map(([compulsion, frequency]) => (
                      <li key={compulsion}>{compulsion}</li>
                    ))}
                  </ol>
                </div>
                <div className='ritual-graph-trigger'>
                  <h3>Historical Distress Levels</h3>
                  <p>
                    <strong>Average Distress Level:</strong> {averageDistress}/5
                  </p>
                  {chartData.length > 0 ? (
                    <ResponsiveContainer width='100%' height={450}>
                      <ComposedChart data={chartData}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='entryNumber' />{' '}
                        {/* X-axis shows entry numbers */}
                        <YAxis
                          ticks={[0, 1, 2, 3, 4, 5]}
                          tickFormatter={tick => distressEmojis[tick] || tick}
                          domain={[0, 5]}
                        />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey='distressLevel' fill='#8884d8' />
                        <Line
                          type='monotone'
                          dataKey='averageDistress'
                          stroke='#82ca9d'
                          strokeWidth={2}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  ) : (
                    <p>No distress data available.</p>
                  )}
                </div>
              </div>
            ) : (
              <></>
            );
          })()}

          {/* **************************************************** */}
          {/* ****************** SUDS TRACKER ******************** */}
          {/* **************************************************** */}
          {(() => {
            return isSuds ? (
              <div className='dashboard'>
                <h2 className='dashboard-title'>SUDS Tracker</h2>
                {allHistoricalData.length > 0 ? (
                  allHistoricalData.map((ritualData, ritualIndex) => {
                    const summary = calculateSummary(ritualData); // Get the summary for this ritual
                    return (
                      <div key={ritualIndex} className='ritual-graph'>
                        <h3>
                          Recorded anxiety levels for{' '}
                          <span className='fear-color'>
                            {ritualData.trigger}
                          </span>
                        </h3>
                        <div className='responsive-wrapper'>
                          <ResponsiveContainer width='100%' height={300}>
                            <LineChart>
                              <CartesianGrid strokeDasharray='3 3' />
                              <XAxis
                                type='number'
                                dataKey='time'
                                tickFormatter={value => `${value} min`}
                                label={{
                                  value: 'Time (min)',
                                  position: 'insideBottomRight',
                                  offset: -5,
                                }}
                              />
                              <YAxis
                                domain={[0, 100]}
                                ticks={[0, 20, 40, 60, 80, 100]}
                                label={{
                                  value: 'Anxiety Level',
                                  angle: -90,
                                  position: 'insideLeft',
                                }}
                              />
                              <Tooltip />
                              <Legend />

                              <ReferenceLine y={80} stroke='red' />
                              <ReferenceLine y={20} stroke='green' />

                              {ritualData.data.map((session, sessionIndex) => {
                                // Log each session's level data to the console
                                console.log(
                                  `Ritual: ${ritualData.trigger}, Session ${
                                    sessionIndex + 1
                                  }`,
                                  session.map(entry => entry.level)
                                );

                                return (
                                  <Line
                                    key={sessionIndex}
                                    data={session}
                                    type='monotone'
                                    dataKey='level'
                                    stroke={
                                      lineColors[
                                        sessionIndex % lineColors.length
                                      ]
                                    }
                                    strokeWidth={2}
                                    dot={<CustomDot />}
                                    name={`Session ${sessionIndex + 1}`}
                                  />
                                );
                              })}
                            </LineChart>
                          </ResponsiveContainer>
                        </div>
                        {/* Trend, Comparative, and Progress summary for this ritual */}
                        <div className='trend-summary'>
                          <p>
                            <strong>Average Anxiety Level:</strong>{' '}
                            {summary.avgLevel} <br />
                            <br />
                            <strong>Trend:</strong>
                            <span className={`trend-${summary.trend}`}>
                              {summary.trend === 'up'
                                ? ' 📈 increased'
                                : summary.trend === 'down'
                                ? ' 📉 decreased'
                                : summary.trend === '-'
                                ? ' (2 sessions needed!)'
                                : ' ⚖️ remained stable'}
                            </span>
                          </p>
                          <p>
                            <strong>Comparative Analysis: </strong>
                            {summary.comparison}
                          </p>
                          <p>
                            <p>
                              <strong>Highest Level:</strong>{' '}
                              {summary.highestLevel} |{' '}
                              <strong>Lowest Level:</strong>{' '}
                              {summary.lowestLevel}
                            </p>
                          </p>
                          <p>
                            <strong>Progress: </strong>
                            {summary.progressPercentage}% improvement
                            {summary.progressPercentage > 0 && (
                              <div className='progress-bar-container'>
                                <div
                                  className='progress-bar'
                                  style={{
                                    width: `${
                                      summary.progressPercentage > 0
                                        ? summary.progressPercentage
                                        : 0
                                    }%`,
                                    backgroundColor:
                                      summary.progressPercentage >= 0
                                        ? 'green'
                                        : 'red',
                                  }}
                                ></div>
                              </div>
                            )}
                          </p>
                          {summary.milestoneReached && (
                            <p className='milestone'>
                              🎉 <strong>Milestone Achieved!</strong>
                              <br /> You've consistently recorded lower anxiety
                              levels or achieved a goal!
                            </p>
                          )}
                          <button
                            className='clear-btn'
                            onClick={() => {
                              handleDelete(ritualData.trigger);
                            }}
                            style={{
                              marginLeft: '85%',
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            );
          })()}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default ClientDashboard;
