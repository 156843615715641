import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore'; // Firestore functions
import { db } from './config/firebaseConfig'; // Import Firestore database
import './EmailSignup.css';

const EmailSignup = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state for async request

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateEmail(email)) {
      setLoading(true); // Start loading

      try {
        // Add the email to the Firestore collection "emails"
        await addDoc(collection(db, 'emails'), { email });

        console.log('Email Submitted:', email);
        setSubmitted(true);
        setEmail(''); // Clear the input after submission
      } catch (error) {
        console.error('Error adding email to Firestore:', error);
        alert('There was an error submitting your email. Please try again later.');
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      alert('Please enter a valid email address.');
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className='email-signup'>
      <h2>Stay Updated About Our Last Added Features!</h2>
      {submitted ? (
        <p>Thank you! We’ll notify you about new updates.</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <input
            type='email'
            placeholder='Enter your email address'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading} // Disable input when loading
          />
          <button type='submit' disabled={loading}>
            {loading ? 'Submitting...' : 'Sign Up'} {/* Change button text when loading */}
          </button>
        </form>
      )}
    </div>
  );
};

export default EmailSignup;
