import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './Privacy.css'; // Optional if you have styles for the TOS page

const Privacy = () => {
  return (
    <>
    <Navbar />
    <div className='privacy-container'>
      <h2>What Information We Collect</h2>
      <p>
        When you sign up on our registration Privacy policy, we collect a username, password, and email address. We also collect a 
        security question and answer for password recovery. Optionally, we collect your first and last name. Once you're 
        registered and using the site regularly, we collect many health measurements that you enter on a daily basis like your 
        mood, anxiety, etc.  
        <br/><br/>
        This site uses Cookies.  Cookies are small pieces of information that are sent back and forth between our website and your 
        computing device to distinguish you from other users.  We use cookies to simplify the process of establishing a session when 
        you log into your account. As long as you are logged in and interacting with the site, a cookie is being sent back and forth to your computer.
      </p>
      <h2>How We Use Your Information</h2>
      <p>
        The information we collect is used to identify you as a user of the website and to organize it and present it back to you. 
        For example when you enter your anxiety levels, we use that information to create your charts.  We also use your 
        information to contact you when you request assistance or to inform you of events or changes on the site.
        <br/><br/>
        We may disclose your information in aggregate form to third parties. When this is done, no personally identifying information 
        is ever disclosed. For example 3rd parties may want to know the number of site visitors or page views within a given period of time.  
        This aggregate information may be disclosed to other users of the site or to current or potential business partners. Again, 
        no personally identifying information is ever disclosed.
        <br/><br/>
        If OCDSerenity is sold or acquired by a third party, that third party will abide by this privacy policy.
      </p>
      <h2>Your Privacy Options</h2>
      <p>
        You can review and modify your personally identifying information using features provided in the site. You can use alias information 
        to protect your identity if you wish. You may request to opt-out of the site by <a href='mailto:contact@ocdserenity.com'>contacting us</a>. 
        "Opting-Out" means that you will be removed from all future e-mailings and you will not be contacted.
      </p>
      <h2>Changes to this Privacy Policy</h2>
      <p>
        On occasion we may need to make changes to this policy to adapt it to changes in the way we accept and use your information. We may also 
        need to make policy changes to comply with current laws or regulations. We reserve the right to change this policy at any time.
      </p>                                                                                                                                                                                                                                                          
    </div>
    <Footer />
    </>
  );
};

export default Privacy;
