// TherapistRegistration.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './TherapistRegistration.css';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import {
  doc,
  setDoc,
  addDoc,
  collection,
  serverTimestamp,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { auth, db } from './config/firebaseConfig'; // Import your firebase config file
import Navbar from './Navbar';
import Footer from './Footer';

const TherapistRegistration = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  const logEvent = async (email, role, eventType, note) => {
    try {
      await addDoc(collection(db, 'Logs'), {
        timestamp: serverTimestamp(),
        eventType,
        email,
        role,
        note,
      });
      console.log('Login event logged successfully');
    } catch (error) {
      console.error('Error logging login event:', error);
    }
  };

// Function to generate a unique 8-character Therapist Secret Key
  const generateUniqueSecretKey = async () => {
    const generateKey = () => Math.random().toString(36).substring(2, 10).toUpperCase();

    let secretKey;
    let isUnique = false;

    // Check for uniqueness in the 'users' collection
    while (!isUnique) {
      secretKey = generateKey();
      const q = query(collection(db, 'users'), where('therapistSecretKey', '==', secretKey));
      const querySnapshot = await getDocs(q);
      isUnique = querySnapshot.empty;
    }

    return secretKey;
  };




  const handleRegister = async e => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
       // Generate a unique Therapist Secret Key
      const therapistSecretKey = await generateUniqueSecretKey();
      // Create a new therapist in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Add therapist data to Firestore with a therapist role
      await setDoc(doc(db, 'users', user.uid), {
        email: email,
        role: 'therapist',
        therapistSecretKey,
        status: 'free',
        createdAt: serverTimestamp(), // Store the account creation date
      });

      // Log the registration event in Firestore
      await logEvent(email, 'therapist', 'registration', 'Therapist registered successfully!');

      setSuccess('Therapist registered successfully!');
      alert('You have been successfully registered as a therapist! You may now log in.');
      setEmail('');
      setPassword('');
      // Redirect to login screnn after successful registration
      navigate('/login');
    } catch (error) {
      await logEvent(email, 'therapist', 'registration', 'Failed to register therapist');
      console.error('Error registering therapist:', error);
      setError('Registration failed. Your password must be at least 6 characters long. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className='therapist-registration'>
        <h2>Therapist Registration</h2>
        <form onSubmit={handleRegister} className='registration-form'>
          <label>
            Email:
            <input
              type='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </label>
          <label>
            Password:
            <input
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
          </label>
          {loading ? (
            <p>Registering...</p>
          ) : (
            <button type='submit'>Register</button>
          )}
          {error && <p className='error'>{error}</p>}
          {success && <p className='success'>{success}</p>}
        </form>
      </div>
      <Footer />
    </>
  );
};

export default TherapistRegistration;