import React, { useEffect, useState } from 'react';
import { db } from './config/firebaseConfig'; // Ensure this path is correct
import { collection, query, where, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import './MessageModal.css'; // Style your modal

const MessageModalClient = ({ clientId, onClose }) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Fetch only unread messages
    const fetchUnreadMessages = () => {
      const messagesQuery = query(
        collection(db, 'messages'),
        where('receiverId', '==', clientId),
        where('read', '==', false) // Filter for unread messages
      );

      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const messagesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(messagesList);

      });

      return () => unsubscribe(); // Cleanup subscription
    };

    fetchUnreadMessages();
  }, [clientId]);

  const handleClose = async () => {
    // Mark all displayed messages as read
    try {
      await Promise.all(
        messages.map((message) => {
          const messageRef = doc(db, 'messages', message.id);
          return updateDoc(messageRef, { read: true });
        })
      );
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }

    // Trigger the onClose callback to close the modal
    onClose();
  };

  return (
    <div className="message-modal-client">
      <div className="modal-content-client">
        <span className="close-client" onClick={handleClose}>
          &times;
        </span>
        <h2>You have a message from your therapist</h2>
        <div className="messages-container-client">
          {messages.length > 0 ? (
            messages.map((message) => (
              <div key={message.id} className="message-client">
                <p>{message.message}</p>
                
              </div>
            ))
          ) : (
            <p>No unread messages found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageModalClient;
