import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc, updateDoc, serverTimestamp, addDoc, collection } from 'firebase/firestore'; // Ensure you import addDoc and collection
import { auth, db } from './config/firebaseConfig';
import './SuccessPage.css';
import Footer from './Footer';

const SuccessPage = () => {
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
	const location = useLocation();

	// Extract userId and stripeSessionId from the URL parameters
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('user_id');
  const role = queryParams.get('role');
  // const stripeSessionId = queryParams.get('session_id');
	
	useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        // Fetch user status from Firestore
        const userRef = doc(db, 'users', userId);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          // log the event in firestore in "logs" with user email, role and a message 'Congratulation! New paid subscriber!'
          const logRef = collection(db, 'Logs'); // Reference to the 'Logs' collection
          const logData = {
            timestamp: serverTimestamp(),
            email: auth.currentUser.email,
            eventType: 'payment',
            role,
            note: 'Congratulation! New paid subscriber!',
          };
          await addDoc(logRef, logData); // Add a new document to the 'Logs' collection

          const userData = docSnap.data();
          // Check if the status is "paid"
          if (userData.status !== 'paid') {
            // Update the status to "paid" if not already set
						console.log('WEBHOOK NOT WORKING');
            await updateDoc(userRef, { status: 'paid' });
            setStatus('paid');
          } else {
						console.log('WEBHOOK WORKING PERFECTLY');
            setStatus('paid');
          }
        }
      } catch (error) {
        console.error('Error fetching user status:', error);
      } finally {
        setLoading(false);
      }
    };

    checkPaymentStatus();
  }, [userId]);

  const handleRedirect = () => {
    if (role === 'therapist') {
      navigate('/therapistdashboard');
    } else if (role === 'individual') {
      navigate('/ClientDashboard');
    } else {
      // Default redirection if role is not recognized
      navigate('/');
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    ); // Optionally show a loading spinner or message while checking
  }

  return (
    <div className="page-container">
    <div className="content-wrap">
    <div className="success-page">
      <div className="message">Payment Successful!</div>
      <div className="description">
        Thank you for your purchase! Your payment has been processed successfully.
        You can now access the features you’ve upgraded to. You can cancel your subscription anytime from the settings menu.
      </div>

      {status !== 'paid' ? (
        <div>
          <p className="error-message">Payment status has not been updated yet. We are fixing it.</p>
        </div>
      ) : (
        <>
          
        </>
      )}

      <button className="redirect-button" onClick={handleRedirect}>
        Go to Dashboard
      </button>
      
    </div>
    </div>
    <Footer />
    </div>
  );
};

export default SuccessPage;
