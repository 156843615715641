import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

const AudioContext = createContext();

export const useAudio = () => useContext(AudioContext);

export const AudioProvider = ({ children }) => {
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const audioRef = useRef(new Audio('https://github.com/Olivier7355/business-model-dashboard/releases/download/v1.0.0/relaxing-sound.mp3'));

  const handleMusicClick = () => {
    setShowAudioPlayer(true);
    audioRef.current.play();
  };

  const stopMusic = () => {
    setShowAudioPlayer(false);
    audioRef.current.pause();
    audioRef.current.currentTime = 0; // Reset audio to start
  };

  useEffect(() => {
    // Cleanup function to pause audio on unmount
    return () => {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  }, []);

  return (
    <AudioContext.Provider value={{ showAudioPlayer, handleMusicClick, stopMusic }}>
      {children}
      {showAudioPlayer && (
        <audio controls autoPlay style={{ position: 'absolute', top: '50px', right: '10px' }}>
          <source src={audioRef.current.src} type='audio/mpeg' />
          Your browser does not support the audio element.
        </audio>
      )}
    </AudioContext.Provider>
  );
};
