// Warning.jsx
import React from 'react';
import './Warning.css'; // Import your CSS styling

const Warning = () => {
  return (
    <div className="warning-container">
      <p>
        ⚠️ <strong>Important:</strong> <br/>To maintain your complete privacy, all data is stored 
        locally in your browser. However, there is a risk of losing this data if you clear your 
        browser's cookies or local storage. Use the export/import buttons in the Dashboard to back 
        up your data.
      </p>
    </div>
  );
}

export default Warning;