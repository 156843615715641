import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './TOS.css'; // Optional if you have styles for the TOS page

const TOS = () => {
  return (
    <>
      <Navbar />
    <div className='tos-container'>
      <h1>Terms of Service</h1>
      <p>
        To use the OCDSerenity.com website you, the User, must agree to the Terms of Service agreement outlined below.
      </p>
      <h2>1. Acceptance of Terms</h2>
      <p>
        By choosing to create an account on OCDSerenity.com website, user represents and warrants that he or she is 13 years old or older and agrees to the terms and 
        conditions set forth in this agreement.
      </p>

      <h2>2. This Site is for Informational Purposes</h2>
      <b>THE SITE DOES NOT PROVIDE MEDICAL ADVICE.</b>
      <p>
        The content on the OCDSerenity.com website, such as text, graphics, images, and other material contained on the OCDSerenity.com website ("Content") are for 
        informational purposes only. The Content is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of 
        your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or 
        delay in seeking it because of something you have read on the OCDSerenity.com website!
        <br/><br/>
        If you think you may have a medical emergency, call your doctor, 911 (US) or 112(EU) immediately. OCDSerenity does not recommend or endorse any specific tests, 
        physicians, products, procedures, opinions, or other information that may be mentioned on the Site. Reliance on any information provided by OCDSerenity, 
        its employees, others appearing on the website at the invitation of OCDSerenity, or other visitors to the website is solely at your own risk.
      </p>
      <h2>3. User Privacy</h2>
      <p>
        User agrees that OCDSerenity shall have technical access to user's account in order to respond to technical questions or to offer technical support.
        <br/>
        If the User chooses to use the site OCDSerenity.com, User agrees that his or her OCDSerenity.com data may be viewed by other individuals. The User has the 
        ability to permit and revoke access to their data at their discretion at any time. User also has the option to keep certain data private.
        <br/>
      </p>
      <h2>4. User Contact</h2>
      <p>
        User agrees to allow OCDSerenity to contact the User by email. OCDSerenity's email contact with User is limited to matters concerning 
        the operation and use of User's account and for matters of instruction on the use and operation of the OCDSerenity.com website. OCDSerenity will 
        NOT contact Users about commercial offers from itself or any other party.
        <br/>
      </p>
      <h2>5. Account Deactivation</h2>
      <p>
        Failure to abide by the terms of this agreement will result in immediate account deactivation.
        <br/>
      </p>
      <h2>6. Refund Policy</h2>
      <p>
        For users who purchase a Paid Subscription, a refund in the amount of the most recent payment is available if the request for refund is received through 
        our <a href='mailto:contact@ocdserenity.com'>contact</a> page within 10 days of purchase. When a refund is issued, the user's Paid Subscription ends and 
        the user's account is set to use the free OCDSerenity Basic plan.
      <br/>
      </p>
      <h2>7. Indemnification</h2>
      <p>
        User agrees to indemnify and hold harmless OCDSerenity, its officers, directors, employees and agents, from any claim or demand, including reasonable 
        attorneys fees, made by any third party due to or arising out of user's conduct, user's use of the Service, any alleged violation of this agreement, or any alleged 
        violation of any rights of another, including but not limited to user's use of any content, trademarks, service marks, trade names, copyrighted or patented material, 
        or other intellectual property used in connection with user's account. OCDSerenity reserves the right, at its own expense, to assume the exclusive 
        defense and control of any matter otherwise subject to indemnification by the user, but doing so shall not excuse user's indemnity obligations.
        <br/>
      </p>
      <h2>8. Disclaimer of Liability</h2>
      <p>
        THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES 
        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. NEITHER THIS AGREEMENT OR ANY DOCUMENTATION FURNISHED UNDER IT IS INTENDED TO EXPRESS 
        OR IMPLY ANY WARRANTY THAT THE ONLINE SITE SERVICES WILL BE UNINTERRUPTED, TIMELY OR ERROR-FREE OR THAT THE SERVICE WILL PROVIDE UNINTERRUPTED, TIMELY 
        OR ERROR-FREE SERVICE. THE SECURITY MECHANISM INCORPORATED INTO THE WEBSITE HAS INHERENT LIMITATIONS AND USER MUST DETERMINE THAT THE WEBSITE ADEQUATELY MEETS 
        ITS REQUIREMENTS. USER ACKNOWLEDGES AND AGREES THAT ANY MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT ITS OWN 
        DISCRETION AND RISK AND THAT USER WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES TO ITS COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL 
        AND/OR DATA. OCDSERENITY, ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS, SHALL NOT BE LIABLE, UNDER ANY CIRCUMSTANCES OR LEGAL THEORIES WHATSOEVER, 
        FOR ANY LOSS OF BUSINESS, PROFITS OR GOODWILL, LOSS OF USE OR DATA, INTERRUPTION OF BUSINESS, OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF 
        ANY CHARACTER, EVEN IF OCDSERENITY IS AWARE OF THE RISK OF SUCH DAMAGES, THAT RESULT IN ANY WAY FROM USERS USE OR INABILITY TO USE THE ONLINE 
        SITE SERVICES, OR THAT RESULT FROM ERRORS, DEFECTS, OMISSIONS, DELAYS IN OPERATION OR TRANSMISSION, OR ANY OTHER FAILURE OF PERFORMANCE OF THE ONLINE SITE 
        SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR LIABILITIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
        <br/>
      </p>
      <h2>9. Agreement Modification</h2>
      <p>
        OCDSerenity reserves the right to modify this agreement at its sole discretion at any time without prior written notice.
        <br/>
      </p>
      <h2>10. General</h2>
      <p>
        This agreement and the relationship between User and OCDSerenity shall be governed by the laws of Spain without regard to its 
        conflict of law provisions. OCDSerenity's failure to exercise or enforce any right or provision of this agreement shall not constitute a waiver 
        of such right or provision. 
        If any provision of this agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor 
        to give effect to the parties intentions as reflected in the provision, and agree that the other provisions of this agreement remain in full force and effect. 
        User agrees that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Service or this agreement 
        must be filed within one (1) year after such claim or cause of action arose, or be forever barred. The section titles in this agreement are for convenience only 
        and have no legal or contractual effect.
      </p>                                                                                                                                
    </div>
     <Footer />
    </>
  );
};

export default TOS;
